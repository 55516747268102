@import '../common-color-system';

$app-brand-accent: #4aa462;
$body-color: #efeff4;
$body-bg-inner: #e3e8ee;
$white: #fff;
$lt-white-1: #fcfcfc;
$lt-white-2: #f7fafc;
$lt-white-3: #fbfcfd;
$black: #000;
$lt-black-1: #06101c;
$cream: #fff6e5;
$mustard: #cf8214;
$pear: #c8da3c;
$gray: #d1d5da;
$lt-grey-1: #848a90;
$lt-grey-2: #5f6369;
$lt-grey-3: #c8ccd0;
$lt-grey-4: #e9ebef;
$lt-grey-5: #bfc2c6;
$lt-grey-6: #bfc2c6;
$lt-grey-7: #e2e4e8;
$lt-grey-8: #d1d3d7;
$lt-grey-9: #8a8d99;
$lt-grey-d-1: #34373f;
$lt-grey-a-1: #dddd;
$lt-green: #4aa462;
//checkbox-theme
$app-checkbox-border: $gray-400;
$app-checkbox-bg: $white;

//base-font
$base-font-color: #06101c;
$base-font-color-1: #1a1f36;

//heading-color
$heading-color: #ff9200;
$menu-bg-color: #444444;

//login
$login-bg: #122358;

//data table

$dt-th-color: #1a1f36;
$dt-td-border: #e3e8ee;

//no data
$nodata-border: #dadfe6;
$nodata-bg: #fdfdfd;
$no-data-text-color: #a6afb9;
$time-block-color: #efefef;
