.coordinates-control-wrapper {
  margin-left: 10px;
  background-color: $white;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: $text-md;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  .row {
    @include flex-wrapv2(nowrap);
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 5px !important;
  .leaflet-popup-content {
    margin: 10px 10px 0 10px;
  }
}

.horizontal-line {
  position: relative;
  width: 100%; // Full-width
  height: 1px; // Line thickness
  border: none;
  background-color: rgb(199, 193, 193);

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%; // Extend to the edge of the container
  }

  &::before {
    margin-bottom: 5px; // Adjust spacing between the top space and the line
  }

  &::after {
    margin-top: 5px; // Adjust spacing between the line and the bottom space
  }
}

.map-info-window-container {
  background-color: $white;
  max-width: 300px;
  .time-block {
    background-color: $time-block-color;
    border-radius: 4px;
    width: 157px;
    padding: 0 4px;
    font-weight: $font-weight-normal;
    font-size: $text-md;
  }
  .map-info-window-header {
    font-weight: $font-weight-extrabold;
    font-size: $text-xl;
    margin-bottom: 5px;
  }
  .map-info-window-label {
    margin: 8px 0;
    color: #34495e;
    font-size: $text-md-plus;
  }
  .flag-container {
    @include flexboxv2();
    .flag-image {
      margin: 6px 4px 0 4px;
    }
  }
  .vessel-tracking-info-window-header {
    @include flexboxv2();
    @include align-itemsv2(center);
    margin-bottom: 5px;
    color: $red;
    font-size: $text-xl;
    .header-text {
      margin-right: $margin-sm;
      font-weight: $font-weight-extrabold;
    }
    .custom-icon {
      font-size: $text-xl;
    }
  }
}

path.leaflet-interactive:focus {
  outline: none;
}

.leaflet-bar.leaflet-control {
  background: transparent;
  border: none;
  cursor: pointer;

  .dx-icon-plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 30px;
    font-size: 20px;
    background-color: #fff;
    color: black;
    border: 1px solid #615f5f;
    border-radius: 2px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
  }
}

.port-info-content {
  width: 60px;
  padding-top: 5px;
}

.legends-control-wrapper {
  margin-left: 10px;
  background-color: $white;
  padding: 5px 10px 5px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: $text-md;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .legends-list {
    @include flexboxv2();
    @include align-itemsv2(center);
    margin-bottom: 5px;
    .image-block {
      height: 25px;
      width: 40px;
      margin-right: 5px;
      align-content: center;
    }
    .line-block {
      margin: 0 5px;
    }
  }
}
