.accordion-item__body-rl-block {
  padding: 12px 17px 10px 16px;
  &.incident-wrap {
    padding: 12px 0px 0px 0px;
  }
  .m-l-cap-wrapper {
    margin-bottom: 37px;
    &.mrbtm-space {
      margin-bottom: 21px;
    }
    .m-l-cap-left-part {
      .m-l-cap-instrn-title {
        font-weight: $font-weight-extrabold;
        font-size: $text-lg;
        line-height: 1.71;
        color: $text-color;
      }
      .m-l-cap-instrns {
        font-weight: $font-weight-normal;
        font-size: $text-lg;
        line-height: 1.71;
        color: $app-brand-secondary;
      }
    }
    .m-l-cap-right-part {
      @media (max-width: 576px) {
        margin-top: 15px;
      }
      .m-l-cap-right-icon-part {
        @include flexboxv2();
        @include justify-contentv2(flex-end);
        @include align-itemsv2(center);
        @media (max-width: 576px) {
          @include justify-contentv2(flex-start);
        }
      }
      .m-l-cap-right-toggle-part {
        @include flexboxv2();
        @include justify-contentv2(flex-end);
        @include align-itemsv2(center);
        margin-top: 37px;
        @media (max-width: 576px) {
          @include justify-contentv2(flex-start);
        }
        .m-l-cap-toggle-switch-label {
          font-weight: $font-weight-bold;
          font-size: $text-md;
          line-height: 1.33;
          color: $text-color;
          margin-right: 19px;
        }
      }
    }
  }
  .m-l-info-attr {
    font-weight: $font-weight-bold;
    font-size: $text-lg;
    line-height: 1.71;
    color: $app-brand-primary;
    margin-right: 16px;
  }
  .m-l-cap-footer {
    @include flexboxv2();
    .m-l-cap-btn {
      margin-left: auto;
    }
  }
}

.m-l-inner-page-tab-section {
  padding: 0px 16px;
  // background-color: #f7f7fc;
}

.m-l-form-panel--details-block {
  margin: 0px 16px 16px;
}

.m-l-form-panel--details-header {
  margin: 0px 16px 16px;
}

.checkbox-label {
  margin-left: 16px;
}

.m-l-search-result-display-block {
  margin: 8px 0px;
  @include flexboxv2();
  @include align-itemsv2(center);
  .right-btn-block {
    margin-left: auto;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.m-l-entity-attribute-wrapper {
  margin-bottom: 32px;
  @include align-itemsv2(center);
  &.right-aligned-data {
    @include justify-contentv2(flex-end);
  }
  .m-l-cap-entity-name {
    font-weight: $font-weight-bold;
    font-size: $text-lg;
    line-height: 1.71;
    color: $text-color;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 576px) {
      margin-bottom: 4px;
    }
  }
  .m-l-cap-attribute-name {
    font-weight: $font-weight-normal;
    font-size: $text-lg;
    line-height: 1.71;
    color: $text-color;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (max-width: 576px) {
      margin-bottom: 5px;
    }
  }
  .m-l-value-dp {
    font-size: $text-lg;
    line-height: 1.71;
    color: $text-color;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
//Mail list
.m-l-page-sub-category-title {
  font-weight: $font-weight-semibold;
  font-size: $text-lg;
  line-height: 1.71;
  color: $app-brand-primary;
  margin-top: 16px;
}

.m-l-attachment-wrapper {
  @include flexboxv2();
  .m-l-attachment-icon-wrapper {
    font-size: $text-md;
    line-height: 1.33;
    color: $app-brand-secondary;
    margin-right: 6px;
  }
}
.m-l-grid-delete-icon-wrapper {
  @include flexboxv2();
  @include justify-contentv2(flex-end);
  width: 100%;
  color: $dark-blue;
  @include transition(color ease 0.2s);
  &:hover {
    color: $app-brand-primary;
    cursor: pointer;
  }
  &.left-align {
    padding: 7px 0px;
    @include justify-contentv2(flex-start);
  }
}
.m-l-inner-page-body {
  &.m-l-inner-maillist {
    height: calc(100% - 130px);
    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
}
.m-l-form-panel-body-block.m-l-add-rule-form {
  &.m-l-ruleform-in-tab {
    padding-top: 24px;
    padding-bottom: 0px;
  }
}
.m-l-icon-btn-grp {
  @include flexboxv2();
  @media screen and (max-width: 575px) {
    @include flex-directionv2(column);
  }
  .min-btn-width-sm-pls {
    @media screen and (max-width: 575px) {
      width: 100% !important;
    }
  }
}
.m-l-page-inner-heading {
  font-weight: $font-weight-bold;
  font-size: $text-xl-sm;
  line-height: 1.33;
  color: $app-brand-primary;
  margin-bottom: 22px;
}
.m-l-cap-instruction-wrap {
  padding-top: 12px;
  @media (max-width: 576px) {
    padding-top: 0px;
  }
}
.m-l-mail-notifier-block {
  position: relative;
  color: $dark-blue;
  width: 24px;
  @include flexboxv2();
  @include align-itemsv2(center);
  cursor: pointer;
  @media screen and (max-width: 767px) {
    margin-left: auto;
    margin-top: 0px;
  }
  .m-l-mail-count-holder {
    right: -6px;
    top: -6px;
    width: 18px;
    height: 18px;
    @include border-radius-all(50%);
    background: $red;
    position: absolute;
    @include flexboxv2();
    @include justify-contentv2(center);
    @include align-itemsv2(center);
    .m-l-mail-count {
      font-weight: 500;
      font-size: 12px;
      line-height: 1.33;
      color: $white;
    }
  }
}
.m-l-rule-toggle-list {
  padding: 0px;
  .m-l-toggle-button-horizontal-list {
    margin-left: 0px;
    margin-right: 0px;
    @include align-itemsv2(stretch);
    @include justify-contentv2(flex-start);
    .m-l-grid-top-header-list-column {
      padding: 13px 16px;
      background: $grd-bg;
      margin-top: 8px;
      margin-bottom: 0px !important;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06);
      @include justify-contentv2(space-between);
      .m-l-toggle-switch {
        padding-left: 16px;
        margin-right: 0px;
      }
    }
  }
}

//tick animation
.tick-svg {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  -webkit-animation: dash 3s linear forwards;
  animation: dash 3s linear forwards;
}
.circle-svg {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  -webkit-animation: dash 3s linear forwards;
  animation: dash 3s linear forwards;
}
@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
//bu grid
:root {
  --height-surface: 100%;
}
.m-l-inner-tabgrid-page-body {
  margin-bottom: 0px;
  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
}
.m-l-bu-grid-form-wrap {
  height: 100%;
  .m-l-with-filter-row-in-grid,
  .m-l-no-filter-row-in-grid {
    height: 100%;
  }
  // .m-c-grid {
  //   height: var(--height-surface) !important;
  //   @media screen and (max-width: 767px) {
  //     height: auto !important;
  //   }
  // }
  .dx-datagrid-rowsview {
    max-height: 100%;
  }
}
.mtop-space {
  margin-top: 16px;
}
.tp-10 {
  top: 10px;
}
.top-8 {
  margin-top: 8px;
}

.appeal-footer {
  margin-top: 8px;
  @media screen and (min-width: 576px) {
    @include flexboxv2();
    @include flex-directionv2(row);
  }
  @media screen and (min-width: 767px) {
    display: none;
  }
}
.m-l-inc-wrapper {
  margin-bottom: 21px;
}
.m-l-page-small-sub-heading {
  color: $app-brand-primary;
  font-size: $text-xl-sm;
  font-weight: $font-weight-semibold;
  @media screen and (max-width: 600px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
//error page
.m-l-msg-display-wrapper {
  height: 100%;
  @include flexboxv2();
  padding: 15px;
  @include align-itemsv2(center);
  @include justify-contentv2(center);
  .m-l-main-msg-container {
    .m-l-main-msg-holder {
      text-align: center;
      .m-l-msg-ttl {
        font-weight: $font-weight-semibold;
        font-size: 67.5918px;
        line-height: 0.67;
        color: $dark-blue;
        @media (max-width: 480px) {
          font-size: 45px;
        }
      }
    }
  }
  .m-l-sub-msg-holder {
    .m-l-sub-msg-ttl {
      font-size: 22px;
      color: $dark-blue;
      text-align: center;
      @media (max-width: 480px) {
        font-size: 20px;
      }
      .m-l-msg-highlight {
        font-weight: $font-weight-bold;
      }
    }
  }
  .m-l-sub-highlight-ttl {
    font-weight: $font-weight-extrabold;
    font-size: 22px;
    line-height: 1.71;
    text-align: center;
    color: $dark-blue;
  }
  .m-l-icon-holder {
    width: 85px;
    height: 85px;
    margin: 0 auto;
    @media (max-width: 480px) {
      width: 70px;
      height: 70px;
    }
  }
}
.m-l-berth-info-wrapper {
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-start);
  .m-l-berth-info-left-part {
    .m-l-page-link {
      color: $app-brand-primary;
      font-size: $text-md;
      font-weight: $font-weight-semibold;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $app-primary-blue;
      }
    }
  }
  .m-l-berth-info-right-part {
    margin-left: -8px;
    .row {
      @include flexboxv2();
      @include justify-contentv2(flex-end);
    }
  }
  .m-l-berth-info-btn-block {
    margin-left: -8px;
    @include flexboxv2();
    @include align-itemsv2(center);
    @include justify-contentv2(flex-end);
  }
}
.m-l-page-link {
  color: $app-brand-primary;
  font-size: $text-md;
  font-weight: $font-weight-semibold;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $app-primary-blue;
  }
}
.app-l-port-heading {
  @include flexboxv2();
  @include align-itemsv2(center);
  .m-l-page-link {
    margin-left: 8px;
  }
}
.left-icon-port {
  margin-right: 8px;
  svg {
    width: 20px;
    height: 20px;
  }
}
.m-l-prvw-column {
  @include flexboxv2();
  @include align-itemsv2(center);
}
.m-l-terminal-accordion-main-block {
  padding-top: 12px;
  @include flexboxv2();
  @include align-itemsv2(flex-start);

  .m-l-terminal-accordion-left-block {
    min-width: 234px;
    height: 100%;
  }
  .m-l-terminal-accordion-right-block {
    margin-left: 16px;
    padding: 16px 0px 0px;
    width: 100%;

    .m-l-page-small-heading {
      margin-bottom: 8px;
      h5 {
        display: inline-block;
        color: $app-brand-primary;
        font-size: $text-xl;
        font-weight: $font-weight-semibold;
        margin-bottom: 0px;
        @media screen and (max-width: 600px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // max-width: 150px;
          max-width: 100%;
        }
        @media screen and (max-width: 480px) {
          // white-space: nowrap;
          font-size: $text-lg;
          max-width: 100%;
          margin-bottom: 12px;
        }
      }
    }
    a {
      margin-left: 16px;
    }
    .m-l-berthfit-info-wrapper {
      @include flexboxv2();
      @include align-itemsv2(center);
      .m-l-berthfit-info-left-part {
        width: 100%;
        h5 {
          display: inline-block;
          color: $app-brand-primary;
          font-size: $text-xl;
          font-weight: $font-weight-semibold;
          margin-bottom: 0px;
          @media screen and (max-width: 600px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // max-width: 150px;
            max-width: 100%;
          }
          @media screen and (max-width: 480px) {
            // white-space: nowrap;
            font-size: $text-lg;
            max-width: 100%;
            margin-bottom: 12px;
          }
        }
      }
      .m-l-berth-info-btn-right-part {
        margin-left: auto;
      }
    }
  }
}
.m-l-port-info {
  color: $app-primary-blue;
  font-size: $text-md;
  font-weight: $font-weight-semibold;
  margin: 0px 0px 0px 16px;
  span {
    color: $app-primary-blue;
    font-size: $text-md;
    font-weight: $font-weight-semibold;
  }
}
.m-l-port-approval-info {
  color: #f2994a;
  font-size: $text-md;
  font-weight: $font-weight-semibold;
  margin: 0px 0px 0px 16px;
  span {
    color: $app-primary-blue;
    font-size: $text-md;
    font-weight: $font-weight-semibold;
  }
}
.m-l-port-approved-info {
  color: #27ae60;
  font-size: $text-md;
  font-weight: $font-weight-semibold;
  margin: 0px 0px 0px 16px;
  span {
    color: $app-primary-blue;
    font-size: $text-md;
    font-weight: $font-weight-semibold;
  }
}
.m-l-view-birthinfo-link {
  @include flexboxv2();
  @include align-itemsv2(center);
}
.m-l-terminal-general-right-block {
  padding: 0px !important;
  margin-left: 0px !important;
  .m-l-form-panel-block {
    .m-l-form-panel-header-block {
      border-bottom: none;
      padding: 8px 16px 8px 16px;
      .m-l-form-panel-header-left-block {
        .m-l-page-small-heading {
          margin-bottom: 0;
        }
      }
    }
    .m-l-form-panel-body-block {
      padding: 8px 16px;
    }
  }
}
.m-l-vertical-tab-email-compose-section {
  @include flexboxv2();
  @include flex-directionv2(column);
  height: 100%;

  .m-l-terminal-accordion-main-block {
    @media screen and (max-width: 767px) {
      display: block;
      padding-top: 0;
    }
    .m-l-terminal-accordion-left-block {
      @media screen and (max-width: 767px) {
        min-width: 0;
        height: auto;
      }
      .m-l-vertical-tab-block {
        @media screen and (max-width: 767px) {
          min-width: 0;
          overflow: hidden;
          overflow-x: auto;
          padding: 10px 15px;
          height: auto;

          @include flexboxv2();
          @include align-itemsv2(center);

          li {
            @media screen and (max-width: 767px) {
              padding-left: 16px;
              padding-right: 16px;

              @include flex-shrinkv2(0);
            }
          }
        }
      }
    }
    .m-l-terminal-accordion-right-block {
      @media screen and (max-width: 576px) {
        margin-top: 16px;
      }
    }
  }
}
.page-navigation-link {
  cursor: pointer;
}
.m-l-checkbox-in-grid-header {
  position: absolute;
  top: 18px;
  left: calc(50% + 12px);
}

.dx-custom-icon {
  color: $dark-blue;
  cursor: pointer;
  margin-right: 5px;
  font-size: $text-lg;
  &:hover {
    color: $semi-black;
  }
}

.help-icon {
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;
  font-size: 15px;
}
.help-icon-text {
  padding: 10px;
}
.help-text ul {
  margin: 0;
  padding-left: 20px;
}

.help-text li {
  list-style-type: disc;
}

.border-margin {
  margin: 0px 10px 0px 10px;
}

.port-template {
  @include flexboxv2();
  @include align-itemsv2(center);
  color: $red;
  .custom-icon {
    margin-right: 2px;
  }
}
.live-tracking-no-data-text {
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(center);
  height: 100%;
  font-size: 22px;
  color: $dark-blue;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 20px;
  }
  font-weight: $font-weight-bold;
}
