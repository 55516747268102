.m-l-container {
  @include flexboxv2();
  min-height: 100vh;
  width: 100%;
  height: 100%;
}
.main-container {
  @include flexboxv2();
  @include flex-directionv2(column);
  width: 100%;
  min-width: 0;
  background: $white;
  // overflow: auto;
}
.inner-wrapper {
  height: 100%;
  // padding: 79px 16px 16px 16px;
  padding: 66px 16px 16px 16px;

  @media screen and (max-width: 891px) {
    padding: 163px 16px 16px 16px;
  }

  // &.m-l-global-search-page-inner-wrap {
  //   background-color: $body-color;
  // }
}
.inner-wrapper-report {
  height: 100%;
  // padding: 79px 16px 16px 16px;
  padding: 16px 16px 16px 16px;

  @media screen and (max-width: 891px) {
    padding: 163px 16px 16px 16px;
  }

  // &.m-l-global-search-page-inner-wrap {
  //   background-color: $body-color;
  // }
}
.m-l-inner-page-header {
  margin-bottom: 8px;
  @include flexboxv2();
  @include align-itemsv2(center);

  @media screen and (max-width: 480px) {
    display: block;
  }
  &.m-l-inner-page-header-with-mail-icon {
    @include align-itemsv2(flex-start);
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .m-l-title-block {
    @include flexboxv2();
    @include align-itemsv2(center);

    .page-navigation-link {
      ~ .m-l-page-icon {
        margin-left: 6px;
      }
    }
  }
  .m-l-inner-page-header-left-block {
    width: 100%;
    .m-l-page-main-heading {
      color: $app-brand-primary;
      // font-size: $text-xl-plus;
      font-size: $text-xl-md;
      font-weight: $font-weight-bold;
      white-space: normal;
      word-break: break-word;
      max-width: 90%;
      @media screen and (max-width: 767px) {
        width: auto;
      }
      @media screen and (max-width: 600px) {
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // max-width: 150px;
        max-width: 100%;
      }
      @media screen and (max-width: 480px) {
        // white-space: nowrap;
        max-width: 100%;
      }
    }
  }
  &.m-l-vsl-page-hd {
    margin-bottom: 18px;
  }
}

.m-l-inner-page-header-right-block {
  margin-left: auto;
  @include flexboxv2();
  @media screen and (max-width: 767px) {
    display: none;
  }
  &.m-l-show-mb {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  @media screen and (max-width: 370px) {
    @include flex-directionv2(column);
  }
  .app-c-btn {
    + .app-c-btn {
      @media screen and (max-width: 370px) {
        margin-left: 0px;
        margin-top: 8px;
      }
    }
  }
  .m-c-form__inline {
    + .m-c-form__inline {
      margin-left: 8px;
      position: relative;
      padding-left: 8px;

      &:before {
        content: '';
        width: 1px;
        height: 100%;
        background: $lt-grey-4;
        position: absolute;
        top: 0;
        left: 0;
      }

      @media screen and (max-width: 370px) {
        margin-left: 0px;
        margin-top: 8px;
        padding-left: 0px;
      }
    }
    ~ .m-c-icon-inside-secondary-button {
      margin-left: 8px;

      @media screen and (max-width: 370px) {
        margin-left: 0px;
        margin-top: 8px;
      }
    }
  }
}
.m-l-tab-panel__wrap {
  position: relative;
  height: 100%;
}
.m-l-tab-panel-right-block {
  position: absolute;
  top: 8px;
  right: 8px;
  @include flexboxv2();
  @include align-itemsv2(center);

  @media screen and (max-width: 767px) {
    display: none;
  }
}
.m-l-page-main-heading {
  color: $app-brand-primary;
  font-size: $text-xl-plus;
  font-weight: $font-weight-bold;

  @media screen and (max-width: 991px) {
    font-size: $text-xl-md;
  }
}
.m-l-page-sub-heading {
  color: $app-brand-primary;
  font-size: $text-lg !important;
  font-weight: $font-weight-semibold !important;
}
.m-l-page-create-company-info {
  background-color: $orange-st;
  padding: 4px 6px;
  margin: 0px 6px;
  border-radius: 4px;
  font-size: $text-lg !important;
  text-align: center;
}
.m-l-sidebar-heading-block {
  padding: 16px 12px;
  .m-l-sidebar-heading {
    color: $white;
    font-size: $text-xl;
    font-weight: $font-weight-extrabold;
    text-align: center;
  }
}
.m-l-attachment-block {
  @include flexboxv2();
  @include flex-wrapv2(wrap);
  @media screen and (max-width: 767px) {
    display: none;
  }
  margin-top: auto;
  .m-l-atch-icon-holder {
    color: $dark-blue;
    margin-right: 10px;
    .m-l-add-atch-txt {
      font-weight: $font-weight-semibold;
      font-size: $text-md;
      line-height: 1.33;
      text-decoration-line: underline;
      color: $app-brand-primary;
      padding-left: 9px;
      cursor: pointer;
    }
  }
  .m-l-upld-fl-holder {
    margin-top: 8px;
    @include flexboxv2();
    @include flex-wrapv2(wrap);
    @media screen and (max-width: 992px) {
      margin-top: 3px;
    }
  }
}
.m-l-upld-fl-wrapper {
  @include flexboxv2();
  @include flex-wrapv2(wrap);
  margin-top: auto;
  .m-l-atch-icon-holder {
    color: $dark-blue;
    margin-right: 10px;
    .m-l-add-atch-txt {
      font-weight: $font-weight-semibold;
      font-size: $text-md;
      line-height: 1.33;
      text-decoration-line: underline;
      color: $app-brand-primary;
      padding-left: 9px;
      cursor: pointer;
    }
  }
  .m-l-upld-fl-holder {
    margin-top: 8px;
    @include flexboxv2();
    @include flex-wrapv2(wrap);
    @media screen and (max-width: 992px) {
      margin-top: 3px;
    }
  }
}
.m-l-inner-page-body {
  margin-bottom: 16px;
  position: relative;
  border: 1px solid $semi-gray;
  background-color: $white;
  @include border-radius-all(8px);
  // height: calc(100% - 70px);
  height: calc(100% - 35px);
  @media screen and (max-width: 767px) {
    height: auto;
  }
  &.m-l-inner-page-body-with-grid {
    height: auto;
    padding-bottom: 12px;
  }
  .m-l-edit-view-mode {
    position: absolute;
    top: -2px;
    right: 22px;
    @media screen and (max-width: 767px) {
      top: -10px;
      right: 8px;
    }
  }
  .m-l-edit-view-engine-tab-mode {
    position: absolute;
    top: 15px;
    right: 14px;
    z-index: 2;
    @media screen and (max-width: 767px) {
      top: 6px;
      right: 6px;
    }
  }
}
.m-l-inner-card-bx {
  position: relative;
  border: 1px solid $semi-gray;
  background-color: $white;
  @include border-radius-all(8px);
  margin-top: 8px;
  margin-right: 8px;
  flex: 1;
  @include align-selfv2(stretch);
  @media screen and (max-width: 767px) {
    height: auto;
  }
}

.m-l-form-panel-body-block {
  padding: 28px 24px;
}
.m-l-form-panel-footer-block {
  @media screen and (max-width: 767px) {
    display: none;
  }
  padding: 0px 24px 24px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-end);
  .app-c-btn {
    min-width: 102px;
  }
  &.search-filter-footer-block {
    @media screen and (min-width: 767px) {
      display: none;
    }
  }
}

.m-l-head-tab-bottom-section {
  padding-top: 18px;
  .m-l-contact-info-block {
    margin-bottom: 14px;
  }
}

// Confirm Dialog box

.m-l-confirmation-dialogbox-wrap {
  height: 100%;
  @include flexboxv2();
  @include flex-directionv2(column);
}
.dx-popup-wrapper {
  &.dx-overlay-shader {
    background-color: rgba(23, 74, 132, 0.4);
  }
  .dx-popup-normal {
    max-height: 322px;
    max-width: 392px;
    padding: 22px 24px 24px;
    // @include border-radius-all(10px !important);
    @include border-radius-all(4px !important);
    .dx-popup-content {
      padding: 0;
      .m-l-confirm-dialogbox-header {
        position: relative;

        .dx-button {
          position: absolute;
          top: -10px;
          right: -10px;
          border: none;
          .dx-button-content {
            .dx-icon {
              color: $dark-blue;
            }
          }
        }
      }
      .m-l-confirm-dialogbox-body {
        height: 100%;
        .m-l-confirm-dialogbox-title-block {
          @include flexboxv2();
          @include flex-directionv2(column);
          @include align-itemsv2(center);
        }
        .m-l-confirmation-dialogbox-title {
          font-size: $text-2xl-plus;
          font-weight: $font-weight-bold;
          color: $app-brand-primary;
          line-height: normal;
          text-align: center;
          margin-top: 16px;
          margin-bottom: 8px;
          @media screen and (max-width: 768px) {
            font-size: $text-xl-plus;
          }
          @media screen and (max-width: 600px) {
            font-size: $text-xl-md;
          }
        }
        .m-l-confirmation-info {
          text-align: center;
          color: $app-brand-secondary;
          font-size: $text-xl;
          font-weight: $font-weight-semibold;
          max-width: 85%;
          margin: 0 auto;
        }
        &.m-l-confirm-dialogbox-change-pwd-wrap {
          margin-bottom: 8px;
        }
      }
      .m-l-confirm-dialogbox-footer {
        @include flexboxv2();
        @include align-itemsv2(center);
        @include justify-contentv2(center);
        .dx-button {
          .dx-button-content {
            padding: 0;
            @include flexboxv2();
            @include align-itemsv2(center);
            @include justify-contentv2(center);
          }
        }
        .app-c-btn {
          width: 100%;
          + .app-c-btn {
            margin-left: 25px;
          }
        }
      }
    }
  }
}
// End Confirm Dialog box
.no-list-block {
  height: 100%;
  @include flexboxv2();
  @include flex-directionv2(column);
  @include align-itemsv2(center);
  @include justify-contentv2(center);
  .no-list-text {
    line-height: normal;
    text-align: center;
    color: $app-brand-secondary;
    font-size: $text-xl;
    font-weight: $font-weight-semibold;
    margin-top: 28px;
    p {
      display: inline-block;
      font-weight: $font-weight-extrabold;
      margin: 0;
    }
  }
  &.no-list-block {
    min-height: 400px;
  }
}
.m-l-panel-footer {
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-end);
}
//Toast message

.m-l-toast-message-wrap {
  background-color: $white;
  position: relative;
  width: 494px;
  min-height: 74px;
  padding: 14px 18px;
  margin-left: auto;
  margin-right: 16px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include border-radius-all(8px);
  @include boxShadow(0px 1px 5px #ccc !important);
  .m-l-toast-message-icon-wrap {
    margin-right: 14px;
  }
  .m-l-toast-message-block {
    text-align: left;
    h2 {
      color: $app-brand-primary;
      font-size: $text-lg;
      font-weight: $font-weight-extrabold;
      line-height: normal;
      margin-bottom: 5px;
    }
    p {
      color: $app-brand-primary;
      font-size: $text-lg;
      font-weight: $font-weight-bold;
      line-height: normal;
      margin-bottom: 0px;
    }
  }
  &.m-l-toast-success-msg-wrap {
    background-color: $toastSuccessBg !important;
  }
  &.m-l-toast-warning-msg-wrap {
    background-color: $toastWarnningBg !important;
  }
  &.m-l-toast-error-msg-wrap {
    background-color: $toastErrorBg !important;
  }
  .toast-close-button {
    position: absolute;
    top: 0px;
    right: 8px;
    cursor: pointer;
  }
}
// form panel styles.

.m-l-form-panel-block {
  // border: 1px solid $semi-gray;
  @include border-radius-all(8px);
  &.m-l-card-form {
    border: 1px solid $semi-gray;

    .m-l-form-panel-header-block {
      border-bottom: none;
      padding: 16px 16px 3px 16px;
    }
    .m-l-form-panel-body-block {
      &.m-l-pdng-btm0 {
        padding-bottom: 0px;
      }
    }
  }
}
.m-l-role-critical {
  color: $red;
  font-size: 16px;
  margin-right: 4px;
  font-weight: 500;
}
.m-l-form-panel-header-block {
  // padding: 8px 16px;
  padding: 8px 8px 8px 16px;
  border-bottom: 1px solid $semi-gray;
  @include flexboxv2();
  @include align-itemsv2(center);

  @media screen and (max-width: 480px) {
    display: block;
  }
}
.m-l-form-panel-header-left-block {
  margin-right: 8px;
  .m-l-page-small-heading {
    color: $app-brand-primary;
    font-size: $text-xl;
    font-weight: $font-weight-semibold;
    @media screen and (max-width: 600px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // max-width: 150px;
      max-width: 100%;
    }
    @media screen and (max-width: 480px) {
      // white-space: nowrap;
      font-size: $text-lg;
      max-width: 100%;
      // margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 480px) {
    margin-right: 0px;
  }
}
.m-l-form-panel-header-right-block {
  margin-left: auto;
  @include flexboxv2();
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.m-l-form-panel-body-block {
  padding: 10px 16px;
  &.m-l-add-rule-form {
    padding: 22px 24px 16px 24px;
  }
}
.m-l-port-warning {
  padding-left: 8px;
}
.m-l-advance-search-block {
  cursor: pointer;
  border: 1px solid $semi-gray;
  background-color: $white;
  @include border-radius-all(8px);
  // @include boxShadow(0px 1px 0px #ebf3fe);
  .m-l-advance-search-header-block {
    border-bottom: 1px solid $semi-gray;
    padding: 11px 16px;
    .m-l-heading {
      color: $app-brand-primary;
      font-size: $text-xl;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }
  .m-l-advance-search-body-block {
    padding: 12px 13px;
    @include flexboxv2();

    .m-l-advance-search-item-block {
      span {
        color: $text-color;
        font-size: $text-md;
        font-weight: $font-weight-bold;
      }
      h5 {
        color: $app-brand-secondary;
        font-size: $text-lg;
        font-weight: $font-weight-normal;
        margin-top: 10px;
        margin-bottom: 0px;
      }
      .m-l-flag-block {
        margin-top: 10px;
        @include flexboxv2();
        img {
          width: 30px;
          height: 22px;
          @include border-radius-all(3px);
        }
        span {
          color: $app-brand-secondary;
          font-size: $text-lg;
          font-weight: $font-weight-semibold;
          margin-left: 16px;
        }
      }
    }
  }
  + .m-l-advance-search-block {
    margin-top: 9px;
  }
  &:hover {
    @include boxShadow(1px 2px 5px #d3dce9);
  }
}
.m-l-results-found {
  color: $app-brand-secondary;
  font-size: $text-lg;
  font-weight: $font-weight-semibold;
  margin-top: 0px;
  margin-bottom: 16px;
}
.icon-stroke {
  stroke: currentColor;
}
.icon-fill {
  fill: currentColor;
}
.m-l-inner-page-body-wrap {
  .m-l-grid-panel-block {
    margin-top: 17px;
    @include border-radius-all(8px);
  }
}
.m-l-srv-btm {
  margin-bottom: 16px;
}
.m-l-srv-pdng {
  // .m-l-form-panel-body-block {
  //   padding: 19px 19px 0px 19px;
  // }
  .m-l-form-panel-footer-block {
    padding: 0px 19px 16px 19px;
  }
}
.m-l-cnt-btn-wrapper {
  @include flexboxv2();
  .app-c-snd-btn {
    margin-left: auto;
  }
}

//advanced search vessel information

.m-l-tbl-square-wrapper {
  padding: 16px 0px;
}
.m-l-tbl-square-wrapper--edit-mode {
  padding: 16px;
}
.m-l-ads-table-block {
  .m-l-ads-table-items {
    padding: 0px 16px 0px 16px;
    // margin-bottom: 28px;
    margin-bottom: 18px;
    border-left: 1px solid rgba($app-brand-secondary, 0.2);
    min-height: 54px;
    @media screen and (max-width: 575px) {
      margin-bottom: 0px;
      padding-left: 16px;
      padding-right: 16px;
    }
    + .m-l-ads-table-items {
      @media screen and (max-width: 575px) {
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: 1px solid rgba($app-brand-secondary, 0.2);
      }
    }

    .m-l-ads-tbl-title {
      font-size: $text-md;
      line-height: 1.33;
      margin-bottom: 8px;
      word-break: break-word;
      font-weight: $font-weight-semibold;
      color: $status-label;
    }
    .m-l-ads-tbl-desc {
      // font-size: $text-lg;
      font-size: $text-lg;
      font-weight: $font-weight-semibold;
      line-height: 1.71;
      color: $app-brand-primary;
      word-break: break-word;
      .ml-ads-tbl-flag {
        width: 30px;
        margin-right: 8px;
        img {
          width: 30px;
          height: 22px;
          @include border-radius-all(3px);
        }
      }
      &.m-l-gr-label {
        color: $sucess;
      }
      &.m-l-red-label {
        color: $red;
      }
    }
    &:first-child {
      @media screen and (max-width: 575px) {
        padding-bottom: 16px;
      }
    }
    &:last-child {
      @media screen and (max-width: 575px) {
        padding-bottom: px;
      }
    }
  }
  .m-l-ads-table-col {
    width: 100%;
    &:first-child {
      .m-l-ads-table-items {
        padding-left: 0px;
        border-left: none;
      }
    }
    .m-l-ads-table-items {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .m-l-ads-table-row {
    position: relative;
    margin: 0px -9px -24px -9px;
    @media screen and (max-width: 575px) {
      margin: 0px;
    }
    &:before {
      position: absolute;
      background-color: $white;
      content: '';
      width: 2px;
      height: 100%;
      display: block;
    }
  }
}
.m-l-page-footer-btn-wrapper {
  padding: 16px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-end);
}
.m-l-rule-details-wrap {
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 138px;
  border: 1px solid $plc-color;
  @include border-radius-all(4px);
  li {
    padding: 8px 16px;
    @include flexboxv2();
    @include align-itemsv2(center);
    .m-c-message-text {
      color: $text-color;
      font-size: $text-md;
      font-weight: $font-weight-bold;
      margin-bottom: 0px;
    }
    &:first-child {
      padding: 10px 16px;
    }
    &:last-child {
      padding: 10px 16px;
    }
  }
  .m-l-rule-list-column1 {
    min-width: 130px;
  }
  .m-l-rule-list-column2 {
    min-width: 110px;
  }
  .m-l-toggle-switch {
    min-width: 50px;
  }
  .m-l-message-icon-wrap {
    cursor: pointer;
    margin-left: 14px;
    &.icon-selected {
    }
  }
}
.m-l-row-custom-column {
  @include flex-growv2(1);
}
.m-l-row-selected {
  padding: 14px 16px 16px 16px;
  border: 1px solid transparent;
  @include border-radius-all(4px);
  &.active {
    border-color: $secondary-brown;
  }
}
.app-c-add-btn-wrap {
  width: 54px;
  position: relative;
}
.app-c-right-most-add-btn-wrap {
  width: 65px;
  &.m-l-adtnl-dt-grp {
    padding-left: 8px;
    padding-right: 8px;
    .m-l-button-vertical-center-align {
      @media screen and (max-width: 576px) {
        height: auto !important;
        min-height: auto !important;
        padding-left: 12px !important;
      }
    }
  }
}
.m-l-sub-section-heading {
  color: $app-brand-secondary;
  font-size: $text-md !important;
  font-weight: $font-weight-bold !important;
  margin-bottom: 3px;
}
.m-l-sub-section {
  padding: 14px 16px;
  border: 1px solid $app-brand-secondary;
  @include border-radius-all(4px);
  margin-bottom: 18px;
  .m-l-rule-details-row {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
//Dashboard user mail block styles
.m-l-mail-list-container {
  padding: 20px 0px;
  .m-l-mail-list {
    list-style: none;
    position: relative;
    .m-l-mail-list-item {
      cursor: pointer;
      @include flexboxv2();
      &:before {
        content: '\2022';
        color: $app-primary-blue;
        font-weight: $font-weight-bold;
        display: inline-block;
        // margin-left: -16px;
        font-size: 18px;
        position: absolute;
        top: -4px;
        font-size: $text-xl-sm;
      }
      .m-mail-list-item-header {
        margin-bottom: 12px;
        .m-l-mail-list-item-frm {
          font-weight: $font-weight-normal;
          font-size: $text-md;
          line-height: 1.33;
          .m-l-mail-from {
            color: $app-brand-secondary;
            margin-right: 3.8px;
          }
          .m-l-mail-ownr {
            font-weight: $font-weight-bold;
            color: $app-brand-primary;
          }
          .m-l-mail-date {
            color: $app-secondary-border-color;
          }
        }
      }
      .m-l-mail-list-item-body {
        font-size: $text-md;
        line-height: 1.33;
        color: $app-brand-secondary;
        .m-l-email-subject {
          .m-l-eml-sbjt-title {
            font-weight: $font-weight-semibold;
            padding-right: 6px;
          }
        }
      }
      + .m-l-mail-list-item {
        margin-top: 20px;
      }
    }
  }
}

.m-l-mail-alr-item {
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    background-color: $grid-row-selection;
  }
  .m-l-usr-mail-info {
    padding-bottom: 6px;
    border-bottom: 2px solid $semi-gray;
    .m-l-mail-from {
      color: $app-brand-secondary;
      margin-right: 3.8px;
    }
    .m-l-mail-ownr {
      font-weight: $font-weight-bold;
      color: $app-brand-primary;
    }
    .m-l-mail-date {
      color: $app-secondary-border-color;
    }
    .m-l-mail-list-item-body {
      font-size: $text-md;
      line-height: 1.33;
      color: $app-brand-secondary;
      .m-l-email-subject {
        .m-l-eml-sbjt-title {
          font-weight: $font-weight-semibold;
          padding-right: 6px;
        }
      }
    }
  }
}

.m-l-vet-comments-notification-mark {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: $red;
  position: absolute;
  top: 10px;
  right: 4px;
}

//grid badge
.m-l-badge-container {
  @include flexboxv2();
  @include align-itemsv2(center);
  .badge {
    min-width: 33px;
    min-height: 19px;
    background: $body-color;
    @include border-radius-all(10px);
    font-weight: $font-weight-normal;
    font-size: $text-md;
    color: $dark-blue;
    margin-left: 16px;
  }
}
.m-l-waiting-appr {
  color: $secondary-brown;
}
.m-l-field-wrap {
  @include flexboxv2();
  @include justify-contentv2(center);
  .m-l-field-labels {
    font-weight: $font-weight-normal;
    line-height: 1.71;
    color: $text-color;
    margin-right: 8px;
  }
}
.m-l-field-wrap-checkbox {
  @include flexboxv2();
  .m-l-field-labels {
    font-weight: $font-weight-normal;
    line-height: 1.71;
    color: $text-color;
    margin-right: 8px;
  }
}
.m-l-grid-nm-field {
  padding-left: 59px;
}
// .m-l-panel-right-btn {
//   text-align: right;
//   padding-right: 16px;
//   padding-bottom: 16px;
// }

.m-l-rule-details-row {
  margin-bottom: 12px;
}

//role screen
.m-l-waiting-appr {
  color: $secondary-brown;
}
.m-l-field-wrap {
  @include flexboxv2();
  .m-l-field-labels {
    font-weight: $font-weight-normal;
    line-height: 1.71;
    color: $text-color;
    margin-right: 16px;
  }
}
.m-l-grid-nm-field {
  padding-left: 59px;
}
.m-l-panel-right-btn {
  text-align: right;
  padding-right: 4px;
  padding-bottom: 16px;
}

//add role screen
.m-l-rl-pnl-body-block {
  padding: 0px;
  .m-l-rl-list-wrap {
    border-bottom: 1px solid $semi-gray;
    margin-left: 0px;
    margin-right: 0px;
    & > * {
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 8px;
      // background: $grd-bg;
      // box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06);
    }
    .m-c-form-group.mb-btm-space {
      margin-bottom: 10px;
    }
  }
  .m-c-tg-switch-field {
    padding-left: 32px;
  }
}
.m-l-adrole-tree-view {
  padding: 24px 28px 8px 28px;
  min-height: 400px;
  @media screen and (max-width: 768px) {
    min-height: auto;
  }
}

//vessel accordion
.m-l-accordion-tab-block {
  border: 1px solid $semi-gray;
  @include border-radius-all(8px);
  margin-top: 16px;
  min-height: 600px;
  .m-c-tab-block.dx-tabpanel {
    .dx-tabpanel-tabs {
      .dx-tabs {
        .dx-tabs-wrapper {
          .dx-tab {
            &.dx-tab-selected {
              .dx-tab-content {
                .dx-tab-text {
                  color: $app-primary-blue;
                }
              }
            }
          }
        }
      }
    }
  }

  .m-c-accordion {
    padding: 16px 8px 8px 8px;
  }
}
// .m-l-tab-inner-button {
//   position: absolute;
//   top: 0;
//   right: 0;
// }

.m-l-advance-search-pagination-section {
  margin-top: 32px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-end);
  .m-l-advance-search-pagination-result {
    margin-right: 8px;
    p {
      color: $pagination-text-color;
      font-size: $text-md;
      font-weight: $font-weight-normal;
      line-height: normal;
      margin-bottom: 0px;
      span {
        color: $pagination-text-color;
        font-size: $text-md;
        font-weight: $font-weight-normal;
        margin-bottom: 0px;
      }
    }
  }
  .m-l-pagination-dropdown-block {
    @include flexboxv2();
    @include align-itemsv2(center);
    a {
      color: $app-primary-blue-light;
      font-size: $text-md;
      font-weight: $font-weight-normal;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $app-primary-blue;
      }
    }
    .pagination-dropdown {
      margin: 0px 8px;
      width: 62px;
      height: 32px;
      .m-c-select-box {
        height: 32px;
        .dx-selectbox-container {
          .dx-texteditor-container {
            .dx-texteditor-input-container {
              .dx-texteditor-input {
                padding: 0px;
                height: 20px;
                min-height: 32px;
                font-size: 12px;
                font-weight: $font-weight-semibold;
                line-height: normal;
                color: $text-color-200;
                text-align: center;
              }
              .dx-placeholder {
                display: none;
                font-size: $text-lg;
                font-weight: $font-weight-normal;
                color: $placeholder-color;
                &::before {
                  padding: 0 6px;
                  color: $placeholder-color;
                }
              }
            }
            .dx-texteditor-buttons-container {
              .dx-dropdowneditor-button {
                width: 20px;
                min-width: 25px;
                .dx-button-content {
                  .dx-dropdowneditor-icon {
                    width: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.m-l-input__group-block {
  @include flexboxv2();
  @include align-itemsv2(center);

  .m-l-left-input-group {
    width: 100%;
  }
  .m-l-right-input-group {
    width: 100%;
    margin-left: 6px;
  }
}
.m-c-radio-button__block {
  height: 100%;
  @include flexboxv2();
  @include align-itemsv2(center);
  .m-c-radio-button {
    // margin-bottom: 10px;
    @media screen and (max-width: 575px) {
      margin-bottom: 16px;
    }
  }
}

//add rule new style
.m-l-rl-flx-rw {
  @include flexboxv2();
  // margin-bottom: 24px;
  margin-bottom: 18px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .m-l-rl-flx-rw-left {
    margin-right: 16px;
    flex: 1;
    @media screen and (max-width: 768px) {
      flex-basis: 100%;
      margin-right: 0px;
    }
  }
  .m-l-rl-flx-rw-right {
    flex-basis: 363px;
    margin-top: 21px;
    @media screen and (max-width: 768px) {
      flex-basis: 100%;
    }
  }
}
.m-l-fm-rmcl-btn {
  @media screen and (max-width: 768px) {
    height: auto !important;
    padding-left: 12px !important;
    min-height: auto !important;
  }
}
//vet request style
.m-l-custom-eight-space-col {
  padding-left: 4px;
  padding-right: 4px;
}

.port-pdng {
  padding-bottom: 16px;
}
.m-l-custom-eight-space-row {
  margin-left: -4px;
  margin-right: -4px;
}
.m-l-custom-sixteen-space-col {
  padding-left: 8px;
  padding-right: 8px;
}
.m-l-custom-sixteen-space-row {
  margin-left: -8px;
  margin-right: -8px;
}
.m-l-ldprt-dischrg-row {
  margin-top: 16px;
  margin-bottom: 0px;
  @media screen and (max-width: 891px) {
    margin-bottom: 0px;
  }
  .m-l-ldprt-part {
    // margin-bottom: 30px;
    margin-bottom: 16px;
  }
}
.m-l-button-vertical-center-align {
  &.m-l-fm-icon-btn {
    @include align-itemsv2(flex-start);
    .app-c-btn {
      margin-top: 22px;
      @media screen and (max-width: 992px) {
        margin-top: 0px;
      }
    }
  }
}

//vet request preview
.m-l-input-data-preview {
  font-weight: $font-weight-semibold;
  font-size: $text-lg;
  line-height: 1.71;
  color: $app-brand-primary;
  word-break: break-word;
  @media screen and (max-width: 992px) {
    margin-bottom: 8px;
  }
}
.mb-8 {
  margin-bottom: 8px;
}
.mtop-4 {
  margin-top: 4px;
}
.m-l-upld-fl-wrapper {
  @include flexboxv2();
  @include flex-wrapv2(wrap);
  margin-top: auto;
  .m-l-atch-icon-holder {
    color: $dark-blue;
    margin-right: 10px;
    .m-l-add-atch-txt {
      font-weight: $font-weight-semibold;
      font-size: $text-md;
      line-height: 1.33;
      text-decoration-line: underline;
      color: $app-brand-primary;
      padding-left: 9px;
      cursor: pointer;
    }
  }
  .m-l-upld-fl-holder {
    margin-top: 8px;
    @include flexboxv2();
    @include flex-wrapv2(wrap);
    @media screen and (max-width: 992px) {
      margin-top: 3px;
    }
  }
}
.m-l-vt-rqst-prvw-row {
  position: relative;
  margin: 0px -44px;
  margin-bottom: 13px;
  & > * {
    @media screen and (max-width: 991px) {
      margin-bottom: 24px;
    }
  }

  &:before {
    position: absolute;
    background-color: $white;
    content: '';
    width: 2px;
    height: 100%;
    display: block;
    left: 11px;
  }
  .m-l-prvw-container {
    @include flexboxv2();
    @include flex-directionv2(column);
    border-left: 1px solid rgba($app-brand-secondary, 0.2);
    padding-left: 32px;
    padding-right: 16px;
    height: 100%;
    .m-c-form-group {
      min-height: 54px;
    }
  }
}
.m-l-flag-icon {
  width: 24px;
  height: 24px;
  margin-top: 3px;
  @media screen and (max-width: 992px) {
    margin-bottom: 8px;
  }
}
.m-c-full-hght-fl-upld {
  height: 100%;
  @include flexboxv2();
  @include align-itemsv2(center);
  .m-l-upld-fl-wrapper {
    margin-top: initial;

    .m-l-atch-icon-holder {
      cursor: pointer;
      @media screen and (max-width: 992px) {
        margin-top: 3px;
      }
    }
  }
}
.m-c-divider {
  color: rgba($app-brand-secondary, 0.2);
}
.m-l-toggle-switch--advance-search {
  margin-top: 11px;
  margin-left: 26px;
}

.m-c-alert-info-wrapper {
  @include flexboxv2();
  @include justify-contentv2(space-between);
  .m-l-alrt-icn {
    margin-bottom: 4px;
    cursor: pointer;
  }
}
//message box
.m-l-message-box {
  position: fixed;
  background-color: $app-primary-blue;
  padding: 10px;
  bottom: 0;
  right: 16px;
}
.m-c-message-box-up-icon {
  cursor: pointer;
  @include transition(rotation ease 0.3s);
  position: absolute;
  right: 19px;
  top: 16px;
  z-index: 1;
  color: $dark-blue;
  &.m-l-toggle-icon {
    transform: rotate(180deg);
  }
  &.m-l-base-icon {
    transform: rotate(0deg);
  }
}
.m-l-panel-from-bottom {
  position: fixed;
  right: 16px;
  max-height: calc(100% - 54px);
  height: auto;
  background: $white;
  // box-shadow: 0px 0px 1px 0px #091e424f;
  box-shadow: 0px 0px 10px 0px rgb(9 30 66 / 22%);
  @include transition(ease-out 0.3s);
  @include border-radius(8px, 8px, 0px, 0px);
  z-index: 99;
  @include flexboxv2();
  @include align-itemsv2(stretch);

  .m-c-tab-in-panel {
    &.m-c-tab-block.dx-tabpanel {
      .dx-tabpanel-tabs .dx-tabs {
        border-bottom-width: 3px;
      }
      .dx-tabpanel-container .dx-multiview-wrapper {
        border-top-width: 3px;
      }
    }
  }

  &.m-l-panel__bottom {
    width: 472px;
    bottom: 0;
    max-width: calc(100% - 32px);
    transform: translateY(calc(100% - 44px));
    transition: 0.3s ease-out;
    &.m-l-appeal-panel {
      width: 202px;
      &.m-l-panel__show {
        width: calc(100% - 104px);
        @media screen and (max-width: 891px) {
          width: calc(100% - 32px);
        }
      }
    }

    // @media screen and (max-width: 600px) {
    //   width: 462px;
    // }
    // @media screen and (max-width: 480px) {
    //   width: calc(100% - 32px);
    // }
  }
  .m-c-panel__container {
    width: 100%;
    @include flexboxv2();
    @include flex-directionv2(column);
    @include border-radius(8px, 8px, 0px, 0px);
    .m-c-tab-block {
      .dx-tabpanel-tabs {
        width: calc(100% - 42px) !important;
        overflow: hidden;
      }
    }
  }
  .m-c-panel__header {
    height: 44px;

    @include border-radius(8px, 8px, 0px, 0px);
    position: relative;
  }

  .m-l-panel-collapsed {
    background: $grid-row-selection;
    @include transition(color ease 0.4s, background ease 0.4s);
    .m-c-tab-block.dx-tabpanel {
      .dx-tabpanel-tabs .dx-tabs {
        .dx-tabs-wrapper {
          .dx-tab {
            &.dx-tab-selected {
              background: $grid-row-selection;
              .dx-tab-content {
                background: $grid-row-selection;
                .dx-tab-text {
                  color: $app-brand-secondary;
                }
              }
            }
          }
        }
      }
    }
  }
  .m-c-panel__body {
    padding: 16px 24px;
    @include flex-shrinkv2(1);
    @include flex-growv2(1);
    overflow-y: auto;
  }
  .m-c-panel__footer {
    padding: 12px 24px;
    // border-top: 1px solid $grid-border-phase;
    @include flexboxv2;
    @include justify-contentv2(flex-end);
    @include flex-shrinkv2(0);
  }
  .m-c-panel-title {
    font-weight: $font-weight-semibold;
    font-size: $text-lg;
    line-height: 1.71;
    color: $app-brand-secondary;
  }
}
.m-l-panel__show {
  &.m-l-panel__bottom {
    bottom: 0;
    transform: translateY(0%);
    transition: 0.3s ease-out;
  }
}
.m-l-panel__hide {
  &.m-l-panel__bottom {
    transform: translateY(calc(100% - 50px));
    transition: 0.3s ease-out;
  }
}
.m-l-rqst-comment-wrapper {
  padding: 20px 16px 16px 16px;
  height: 100%;
  .m-l-rqst-msg-items {
    // max-height: calc(100% - 110px);
    // min-height: 300px;
    height: 300px;

    overflow: auto;
  }
  .m-l-rqst-msg-item {
    padding-bottom: 16px;
    + .m-l-rqst-msg-item {
      border-top: 1px solid $semi-gray;
      padding-top: 16px;
    }
    .m-l-name-date-row {
      @include flexboxv2();
      margin-bottom: 4px;
      .m-l-usr-name {
        font-weight: $font-weight-bold;
        font-size: $text-md;
        line-height: 1.33;
        color: $app-brand-primary;
      }
      .m-l-usr-date-time {
        font-weight: $font-weight-normal;
        font-size: $text-md;
        line-height: 1.33;
        color: $app-secondary-border-color;
        padding-left: 17px;
        margin-bottom: 0px;
        li {
          .m-l-usr-dt-item {
            position: relative;
            left: -6px;
          }
        }
      }
    }
    .m-l-rqst-msg-block {
      font-weight: $font-weight-normal;
      font-size: $text-lg;
      line-height: 1.71;
      color: $app-brand-secondary;
    }
  }
  .m-l-msg-input-field {
    padding: 10px 0px 10px 0px;
  }
  .m-l-msg-snd-btn {
    @include flexboxv2();
    .app-c-add-btn {
      margin-left: auto;
    }
  }
  &.m-l-appl-history-wrap {
    padding: 16px;
  }
}
.m-l-fl-wrapper {
  font-weight: $font-weight-semibold;
  font-size: $text-md;
  line-height: 1.3;
  padding: 2px 10px;
  background: $grid-row-selection;
  border-radius: 34px;
  margin-bottom: 5px;
  min-height: 20px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  // + .m-l-fl-wrapper {
  //   margin-left: 5px;
  // }
  .m-l-fl-close {
    padding-right: 6.5px;
    cursor: pointer;
  }
  &.m-l-badge-in-input-box {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    color: $app-brand-primary;
    font-size: $text-sm-plus;
    font-weight: $font-weight-normal;
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
//responsive styles
.m-l-group-button-btm-holder {
  display: none;
  @media screen and (max-width: 767px) {
    @include flexboxv2();
  }
  .m-l-group-button-btms {
    margin-left: auto;

    @include flexboxv2();

    @media screen and (max-width: 575px) {
      margin-left: 0;
      width: 100%;
      @include flex-directionv2(column);
    }
    .app-c-btn {
      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
      + .app-c-btn {
        @media screen and (max-width: 575px) {
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }
  }
  &.m-l-page-with-btm-panel {
    margin-bottom: 60px;
  }
}
.ml-port-tab-footer-padding {
  margin-bottom: 10px;
  margin-top: 10px;
}
.ml-port-footer-padding {
  margin-bottom: 10px;
}
.m-l-inner-page-footer-right-block {
  display: none;
  margin-top: 24px;
  @media screen and (max-width: 767px) {
    @include flexboxv2();
  }
  .m-l-page-footer-btn-group {
    margin-left: auto;
    @media screen and (max-width: 575px) {
      width: 100%;
      margin-left: 0px;
    }
  }

  .app-c-btn {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
    + .app-c-btn {
      @media screen and (max-width: 575px) {
        margin-left: 0px;
        margin-top: 8px;
      }
    }
    &.m-c-icon-inside-secondary-button {
      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
    }
  }
  &.m-l-form-mb-btm-btn {
    @media screen and (max-width: 767px) {
      margin-top: 0px;
      padding: 0px;
    }
  }
}
.m-l-card-form {
  @media screen and (max-width: 992px) {
    .m-l-lp-data {
      .m-l-fm-icon-btn {
        &.m-l-button-vertical-center-align {
          height: auto;
          width: 100%;
          min-height: auto;
          .min-btn-width-sm-pls {
            width: 100% !important;
          }
          .app-c-btn--remove {
            width: 100%;
          }
        }
      }
      + .m-l-lp-data {
        border-top: 1px solid $notification-border;
        padding-top: 22px;
      }
    }
  }
}
.m-l-label-icon-wrap {
  @include flexboxv2();
  @include align-itemsv2(center);
  .m-l-tooltip-show-icon {
    margin-left: 9px;
    margin-top: -8px;
    cursor: pointer;
    color: $app-secondary-border-color;
    @include transition(color ease 0.2s);
    &:hover {
      color: $app-primary-blue;
    }
  }
}
.m-l-preview-fm {
  .m-c-form-group {
    @media screen and (max-width: 991px) {
      margin-bottom: 24px;
    }
  }
}

//active state bg color
.dx-dropdownlist-popup-wrapper,
.dx-dropdownbutton-popup-wrapper {
  .dx-state-active {
    background-color: $grid-row-selection !important;
  }
}

//list screen
.m-l-list-form-panel {
  padding: 22px 16px 0px 16px;
  .m-c-form-group {
    &.mb-btm-space-34 {
      @media screen and (max-width: 575px) {
        margin-bottom: 28px;
      }
    }
  }
}

.m-l-inner-page-sub-header {
  margin-bottom: 9px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @media screen and (max-width: 480px) {
    display: block;
  }
  .m-l-inner-page-sub-header-left-block {
    .m-l-page-main-heading {
      color: $app-brand-primary;
      font-size: $text-xl-sm;
      font-weight: $font-weight-bold;
      @media screen and (max-width: 600px) {
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
  }
}
.m-l-inner-page-sub-header-right-block {
  margin-left: auto;
  @include flexboxv2();
  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 370px) {
    @include flex-directionv2(column);
  }
  .app-c-btn {
    + .app-c-btn {
      @media screen and (max-width: 370px) {
        margin-left: 0px;
        margin-top: 8px;
      }
    }
  }
}
.m-l-sub-head-top {
  padding-top: 8px;
  @media screen and (max-width: 575px) {
    padding-top: 24px;
  }
}
.m-l-details-page-small-heading {
  color: $app-brand-primary;
  font-size: $text-xl;
  font-weight: $font-weight-bold;
  margin: 24px 0px 12px;
  @media screen and (max-width: 600px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // max-width: 150px;
    max-width: 100%;
  }
  @media screen and (max-width: 480px) {
    // white-space: nowrap;
    font-size: $text-lg;
    max-width: 100%;
    margin-bottom: 12px;
  }
}
.m-l-details-page-small-heading--main-engine {
  margin: 16px 0px 16px;
}
.m-l-details-page-small-heading--srubber-engine {
  margin: 12px 0px 16px;
}

//Appeal screen
.m-l-appl-outer-wrapper {
  // padding: 24px 16px;
  padding: 12px 16px;
  min-height: 64px;
  &.m-l-vertical-align-cnt {
    @include flexboxv2();
    @include align-itemsv2(center);
  }
  .m-c-rcmnd-comment {
    font-weight: $font-weight-normal;
    font-size: $text-lg;
    line-height: 1.71;
    color: $app-brand-secondary;
  }
}
.m-l-group-hdr {
  @include flexboxv2();
}
.m-l-sub-title-details-block {
  @include flexboxv2();
  @include align-itemsv2(center);
  @include flex-wrapv2(wrap);
  .m-l-status-block {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.m-l-page-id-dts {
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  @media screen and (max-width: 480px) {
    display: block;
    padding-left: 0px;
  }
}
.m-l-page-id-text {
  // margin-left: 16px;
  color: $app-brand-primary !important;
  font-size: $text-lg !important;
  font-weight: $font-weight-semibold !important;
}
.m-l-bt-outer-wrap {
  @include flexboxv2();
  @media screen and (max-width: 575px) {
    @include flex-directionv2(column);
  }
}
.m-l-status-block {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 4px;

  .m-c-form-group {
    @include flexboxv2();
    @include align-itemsv2(center);
    .m-l-input-label {
      font-weight: $font-weight-semibold;
      font-size: $text-md;
      line-height: 1.33;
      color: $status-label;
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 0px;
  }
  &.open {
    svg {
      @include transform(rotate(180deg));
    }
  }
}
.m-l-status-block-dropdown {
  position: relative;
}
.m-l-appl-comment-wrapper {
  border: 1px solid $semi-gray;
  @include border-radius-all(8px);
  // padding: 24px 16px 16px 16px;
  padding: 12px 16px 12px 16px;
  margin-bottom: 8px;
  .m-l-appl-comment-section {
    .m-l-appl-title {
      font-weight: $font-weight-bold;
      font-size: $text-md;
      line-height: 1.33;
      color: $text-color;
      margin-bottom: 8px;
    }
    .m-l-appl-cmnt {
      font-weight: $font-weight-normal;
      font-size: $text-lg;
      line-height: 1.71;
      color: $app-brand-secondary;
    }
  }
  .m-l-appl-commentor {
    @include flexboxv2();
    @include justify-contentv2(flex-end);
    .m-l-appl-commentor-name {
      font-weight: $font-weight-bold;
      font-size: $text-md;
      line-height: 1.33;
      color: $app-brand-primary;
    }
  }
}
.m-l-appl-date {
  font-weight: $font-weight-normal;
  font-size: $text-md;
  line-height: 1.33;
  color: $app-secondary-border-color;
  padding-left: 18px;
  margin-bottom: 0px;
  li {
    .m-l-usr-dt-item {
      position: relative;
      left: -6px;
    }
  }
}
.m-l-inner-page-body {
  &.m-l-cstab-pdng {
    margin-bottom: 8px;
  }
}
.m-l-page-with-bottom-panel {
  padding-bottom: 53px;
}
.m-l-apl-btn-wrap {
  position: relative;
}
.m-l-appeal-drp-dwn {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.m-l-status-btn {
  font-weight: $font-weight-semibold;
  font-size: $text-lg;
  line-height: 1.71;
  min-height: 28px;
  // min-width: 83px;
  max-width: 220px;
  padding: 2px 16px;
  // max-width: fit-content;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(center);
  @include border-radius-all(4px);
  @include transition(box-shadow ease 0.2s);
  position: relative;

  background: $white;
  border: 1px solid $grid-row-selection;
  .dx-button-content {
    padding: 0px;
    margin-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.3;
  }
  @media screen and (max-width: 420px) {
    min-width: 51px;
  }
  &.dx-state-hover {
    background-color: $white;
    border-color: $grid-row-selection;
    @include boxShadow(0px 1px 5px $gray-lighter);
  }

  &.dx-state-focus,
  &.dx-state-active,
  &.dx-state-focused {
    background: $grid-row-selection;
    border: 1px solid $grid-row-selection;
    @include boxShadow(0px 1px 5px $gray-lighter);
  }
  @media screen and (max-width: 575px) {
    max-width: fit-content;
  }
  &.m-l-status-btn-no-icon {
    .dx-button-content {
      margin-right: 0px;
    }
  }
  &.m-l-status-btn-with-arrow {
    min-width: 125px;
    min-height: 40px;
    @include border-radius-all(4px);
    border: 1px solid $app-secondary-border-color;
    @include justify-contentv2(space-between);
    box-shadow: none;
    + .m-l-status-dropdown-box {
      top: 40px;
    }
    &:after {
      content: url('../../../assets/images/icons/chevron-down.svg');
      position: absolute;
      right: 12px;
      top: 48%;
      transform: translateY(-50%);
    }
  }
}
.status-dropdown-arrow-block {
  margin-left: 8px;
}
//color
.status-bg-orange {
  color: $orange-st;
}
.status-bg-orange-border {
  border: 1px solid #ddc9b6;
}
.status-bg-green {
  color: $green-sw;
}
.status-bg-green-border {
  border: 1px solid #d5f6e3;
}
.status-bg-red {
  color: $secondary-red;
  // border: 1px solid #fce8e8;
}
.status-bg-red-border {
  border: 1px solid #fce8e8;
}
.status-bg-grey {
  color: $plc-color;
  // border: 1px solid #fce8e8;
}
.status-bg-grey-border {
  border: 1px solid $plc-color;
}
.m-l-status-dropdown-box {
  background-color: $white;
  width: 222px;
  border: 1px solid $notification-border;
  position: absolute;
  top: 29px;
  left: 0px;
  overflow: auto;
  z-index: 99;
  @include border-radius-all(4px);
  @include boxShadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @include transition(all 0.3s ease);

  .m-l-status-items {
    padding-top: 8px;
    padding-bottom: 8px;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    .m-l-status-item {
      padding: 7px 16px;
      font-weight: $font-weight-semibold;
      font-size: $text-lg;
      line-height: 1.71;
      cursor: pointer;
      &:hover {
        background-color: $grid-row-selection;
      }
    }
  }
}
.m-l-details-page-sepeartor {
  padding: 8px 0px;
  background-image: linear-gradient(
    to right,
    $app-secondary-border-color 33%,
    $white 0%
  );
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
.m-c-form-group .m-l-input-label {
  &.m-l-status-label {
    color: $status-label;
  }
}
.m-l-tbl-square-section-p-b {
  padding-bottom: 36px;
}
.m-l-form-panel--request-details {
  padding-top: 16px;
  padding-bottom: 16px;
}
.m-l-tbl-square-wrapper--p-b-8 {
  padding-bottom: 8px;
}
.m-l-form-panel-block--tab-mode {
  padding-left: 3px;
  padding-right: 3px;
}
.m-l-custom-sixteen-space-col-wrap {
  padding-left: 16px;
  padding-right: 16px;
}
.m-l-tbl-square-wrapper--edit-mode-diementions {
  padding: 16px 12px;
}
.m-l-form-panel--vet-request-block {
  padding: 0px 2px;
}
.m-l-ads-table-block {
  .m-l-ads-table--inner-row {
    &::before {
      width: 6px;
    }
  }
}
.dkp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.m-l-list-title-block {
  width: 100%;
}
.m-l-ttl-status-wrap {
  @include flexboxv2();
  @media screen and (max-width: 767px) {
    @include flex-directionv2(column);
  }
  .m-l-br-ttl-hldr {
    width: 100%;
  }
}
.app-c-right-section-btn {
  margin-left: auto;
}
.m-l-rqst-edit-icon {
  width: 14px;
  height: 14px;
  margin-left: auto;
  color: $dark-blue;
  margin-right: 4px;
  cursor: pointer;
  @include transition(color ease 0.2s);
  &:hover {
    color: $app-primary-blue;
  }
}
.m-l-berthfit-panel-header-block {
  padding: 8px 0px 8px;
  border: none;
  .m-l-page-link {
    padding-left: 16px;
  }
  .m-l-page-small-heading {
    color: $app-primary-blue;
  }
}

.m-l-view-info-port {
  margin-left: 16px;
  background-color: $grid-row-selection;
  padding: 2px 10px;
  line-height: 22px;
  cursor: pointer;
  @include border-radius-all(34px);
  span {
    color: $app-primary-blue;
    font-size: $text-md;
    font-weight: $font-weight-semibold;
  }
  &.m-l-view-info-link--web-view {
    display: inline-block;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.m-l-flag-wrap {
  @include flexboxv2();
  @include align-itemsv2(center);
  .ml-ads-tbl-flag {
    margin-right: 8px;
  }
}
.app-l-vertical-seperator {
  width: 1px;
  height: 20px;
  background-color: #dadaf2;
  margin: 0px 16px;
}

.ml-form-space-fix__information {
  margin-left: -3px;
  margin-right: -4px;
}

.m-l-upld__birth-fit {
  @include flex-wrapv2(nowrap);

  @media screen and (max-width: 767px) {
    @include flex-directionv2(column);
  }
  .m-l-atch-icon-holder {
    @include flex-shrinkv2(0);

    ~ .m-l-upld-fl-holder {
      margin-top: 0;

      @media screen and (max-width: 767px) {
        margin-top: 8px;
      }
    }
  }
}

// .m-l-form-company-details {
//   .m-l-srv-btm {
//     .m-c-text-editor {
//       min-height: 250px;
//     }
//   }
// }
// Scrollbar stayle

html {
  --scrollbarBG: #ebf3fe;
  --thumbBG: #174a84;
}
::-webkit-scrollbar {
  width: 11px;
  background-color: var(--thumbBG);
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  @include border-radius-all(20px);
  // border: 3px solid var(--scrollbarBG);
}

.m-c-form__report {
  width: 230px;
}

.dx-scrollable-wrapper {
  .dx-scrollable-container {
    .dx-scrollable-scrollbar {
      background: var(--scrollbarBG);

      .dx-scrollable-scroll {
        .dx-scrollable-scroll-content {
          background: var(--thumbBG);

          @include border-radius-all(20px);
        }
      }
    }
  }
}

.m-l-form__fieldset {
  border: 1px solid $semi-gray;
  padding: 20px 22px;
  position: relative;

  @include border-radius-all(8px);

  @media screen and (max-width: 991px) {
    margin-top: 8px;
  }

  .m-l-form__legend {
    position: absolute;
    top: -7px;
    left: 18px;
    background: $white;
    padding: 0 5px;
    line-height: 1;
    font-weight: 500;
    color: #174a84;
  }
  .m-l-col__expand {
    width: calc(100% - 150px);

    @include flex-shrinkv2(0);
    @include flex-basisv2(calc(100% - 150px));
  }
  .m-l-col__fixed {
    width: 150px;

    @include flex-shrinkv2(0);
  }
}

.m-l-form__fieldset-terminal {
  border: 1px solid $semi-gray;
  @media screen and (min-width: 991px) {
    margin-top: 16px;
  }
  padding: 20px 0px 0px 0px;
  position: relative;

  @include border-radius-all(8px);

  @media screen and (max-width: 991px) {
    margin-top: 8px;
  }

  .m-l-form__legend {
    position: absolute;
    top: -7px;
    left: 18px;
    background: $white;
    padding: 0 5px;
    line-height: 1;
    font-weight: 500;
    color: #174a84;
  }
  .m-l-col__expand {
    // width: calc(100% - 150px);

    @include flex-shrinkv2(0);
    @include flex-basisv2(calc(100% - 150px));
  }
  // .m-l-col__fixed {
  //   width: 150px;

  //   @include flex-shrinkv2(0);
  // }
}

.m-l-terminal-block {
  @include flexboxv2();
  @include align-itemsv2(center);
  .left-block {
    flex: 3;
    width: 100%;
  }
  .right-block {
    flex: 1.5;
    width: 100%;
  }
}

.m-l-incident__block {
  .m-c-radio-button {
    &.m-c-custom-space-radiobtn {
      .dx-radiogroup-horizontal {
        .dx-radiobutton {
          margin-right: 16px;
        }
      }
    }
  }
}

.m-l-search__collapse {
  > .row {
    > div {
      width: 25%;

      @media screen and (max-width: 767px) {
        width: 50%;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }
}

.m-l-profile__image {
  position: relative;
  max-width: 200px;
  border: $semi-gray 1px solid;
  padding: 8px;

  @include inline-flexv2();
  @include border-radius-all(4px);

  .app-c-icon-only-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.m-l-warning-banner-wrap {
  @include flexboxv2();
  @include align-itemsv2(center);
  @include flex-wrapv2(wrap);
}

.m-l-tab-panel-berth-date {
  @include flexboxv2();
  @include align-itemsv2(center);
  height: 36px !important;
  margin-right: 16px;
  color: $app-brand-primary;
  font-size: $text-lg !important;
  font-weight: $font-weight-bold !important;
}

.m-l-tab-panel-map-view {
  min-height: 317px;
  height: calc(95vh - 210px);
}

.adjust-margin {
  margin-bottom: -50px;
}
.sts-prone-area-padding {
  padding: 6px;
}
.next-button-container {
  margin-left: auto;
  position: absolute;
  top: 44px;
  right: 25px;
}
.dx-custom-icon.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.summary-footer {
  padding-top: 13px;
}

.report-date-padding {
  padding-right: 11px;
}
.generated-by {
  display: block;
  font-weight: bold;
}
.attachments {
  @include flexboxv2();
  @include flex-wrapv2(wrap);
  gap: 5px;
}

.sanctions-accordion-title-container {
  @include flexboxv2();
}

.report-summary-height {
  margin-bottom: -12px;
}

.text-area-height {
  min-height: 163px !important;
}

.comment-attachments {
  cursor: pointer;
}

.check-box-vet-req {
  display: block;
  margin: auto;
}

.validate-sanctions-banner {
  @include flexboxv2();
  @include justify-contentv2(flex-end);
}

.sanction-config-over-view-editor {
  border-top: 1px solid #ccc;
  padding-top: 22px;
}

.sanc-config-upload-dwnld {
  padding-top: 10px;
  gap: 74px;
}

.sanc-upload-ui {
  @include align-itemsv2(center);
  @include flexboxv2();
  margin-bottom: 12px;
}

.sanc--attach-icon {
  @include align-itemsv2(center);
  @include flexboxv2();
}

.sanc-tooltip-icon {
  margin-left: -6px;
}

.sanc-download-file {
  @include align-itemsv2(center);
  @include flexboxv2();
  margin-bottom: 17px;
}

.sanc-dwnld-icon {
  margin-right: -17px;
}
.sanction-check-info-banner {
  @include flexboxv2();
  padding-top: 8px;
}
