.m-c-form-group {
  width: 100%;
  .m-l-input-label {
    color: $status-label;
    font-size: $text-md;
    font-weight: 500;
    margin-bottom: 4px;
    .m-l-input-required {
      color: $red;
      font-size: $text-md-plus;
      margin-left: 2px;
    }
    .m-l-port-warning {
      padding-left: 5px;
      // cursor: pointer;
    }
  }
  .m-c-text-area-control-h-138 {
    height: 138px;
  }
  .m-c-text-area-control {
    .dx-texteditor-container {
      .dx-texteditor-input-container {
        .dx-texteditor-input {
          height: 100% !important;
        }
        .dx-placeholder {
          font-size: $text-lg;
          font-weight: $font-weight-normal;
          color: $placeholder-color;
        }
      }
    }
  }
  .m-l-port-warning {
    padding-left: 5px;
    // cursor: pointer;
  }
  .m-c-input-control {
    width: 100%;
    @include transition(margin-bottom ease 0.2s);
    .dx-dropdowneditor-input-wrapper {
      height: 33px;
    }
    &.dx-state-focused {
      border-color: $app-secondary-border-color !important;
    }
    &.dx-editor-outlined {
      border: 1px solid $app-secondary-border-color;
    }

    .dx-texteditor-container {
      .dx-texteditor-input-container {
        .dx-texteditor-input {
          color: $app-brand-secondary;
          padding: 6px 8px 6px 8px;
          height: 33px;
          min-height: 33px;
          cursor: auto;
          @include border-radius-all(4px);
        }
        .dx-placeholder {
          font-size: $text-lg;
          font-weight: $font-weight-normal;
          color: $placeholder-color;
        }
      }
    }
    &.active-textbox {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            color: $sucess;
          }
        }
      }
    }
    &.inactive-textbox {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            color: $red;
          }
        }
      }
    }
    .active-lbl {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            color: $sucess;
          }
        }
      }
    }
    .inactive-lbl {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            color: $red;
          }
        }
      }
    }
    .draft-lbl {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          .dx-texteditor-input {
            color: $secondary-brown;
          }
        }
      }
    }
    &.dx-invalid {
      border-color: $red !important;
      // margin-bottom: 15px;
    }
    &.m-c-input-password {
      input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
      .dx-texteditor-container {
        .dx-texteditor-buttons-container {
          .dx-button-mode-contained {
            border: none;
            background-color: $white;
            margin: 0px;
            .dx-button-content {
              padding: 0px;
              padding-right: 12px;
              padding-left: 12px;
              .dx-icon {
                width: auto;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
  &.mb-btm-space {
    margin-bottom: 18px;
  }
  &.mb-btm-space-24 {
    margin-bottom: 18px;
  }
  &.mb-btm-space-22 {
    margin-bottom: 18px;
  }
  &.mb-btm-space-34 {
    margin-bottom: 18px;
  }
  &.mb-btm-space-20 {
    margin-bottom: 18px;
  }
  &.mb-btm-space-30 {
    margin-bottom: 18px;
  }
  .dx-dropdowneditor-icon::before {
    content: '\f016';
    font-size: 10px;
    color: $dark-blue;
  }
}
.m-l-email-input-label {
  color: $status-label;
  font-size: $text-md;
  font-weight: 500;
  margin-bottom: 4px;
  .m-l-input-required {
    color: $red;
    font-size: $text-md-plus;
    margin-left: 2px;
  }
}
.m-l-header-search-area-block {
  .m-c-input-control {
    .dx-texteditor-input-container {
      .dx-texteditor-input {
        color: $app-brand-secondary;
        padding: 6px 33px 6px 8px;
      }
      .dx-placeholder {
        font-size: $text-lg;
        font-weight: $font-weight-normal;
        color: $placeholder-color;
        &::before {
          color: $placeholder-color;
          padding: 6px 32px 5px 10px;
        }
      }
    }
  }
}
.m-l-grid-clone-popup-wrap {
  .m-l-grid-clone-popup-header {
    .m-c-input-control {
      .dx-texteditor-input-container {
        height: 40px;
        .dx-texteditor-input {
          color: $app-brand-secondary;
          padding: 6px 8px 6px 28px;
        }
        .dx-placeholder {
          top: 1px !important;
          left: 20px !important;
          font-size: $text-lg;
          font-weight: $font-weight-normal;
          color: $placeholder-color;
          &::before {
            color: $placeholder-color;
          }
        }
      }
      .dx-selectbox-container {
        .dx-texteditor-input-container {
          .dx-placeholder {
            top: 1px !important;
            left: 0px !important;
            font-size: $text-lg;
            font-weight: $font-weight-normal;
            color: $placeholder-color;
            &::before {
              color: $placeholder-color;
            }
          }
        }
      }
    }
  }
}
.m-l-input-search-area-block {
  .m-c-input-control {
    .dx-texteditor-input-container {
      .dx-texteditor-input {
        color: $app-brand-secondary;
        padding: 6px 38px 6px 8px;
      }
      .dx-placeholder {
        font-size: $text-lg;
        font-weight: $font-weight-normal;
        color: $placeholder-color;
        &::before {
          color: $placeholder-color;
        }
      }
    }
  }
}
.m-c-input-row-block {
  margin: 0px -12px;
  @include flexboxv2();
  @include align-itemsv2(flex-start);
  @include flex-wrapv2(wrap);
  .m-c-input-column-block {
    width: calc((100% / 4) - 20px);
    margin: 0px 8px 18px 12px;
    @include flexboxv2();
    @include flex-directionv2(column);
    @include align-itemsv2(center);

    @media screen and (max-width: 991px) {
      width: calc((100% / 3) - 24px);
    }
    @media screen and (max-width: 768px) {
      width: calc((100% / 2) - 24px);
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .m-c-form-group {
    width: 100%;
  }
}
.m-l-input-group-controller {
  width: 100%;
  @include flexboxv2();
  @include align-itemsv2(center);
  .dx-selectbox {
    width: 100px;
    &.dx-editor-outlined {
      @include border-radius(4px, 0px, 0px, 4px !important);
      border-left: 1px solid $app-secondary-border-color;
      border-top: 1px solid $app-secondary-border-color;
      border-bottom: 1px solid $app-secondary-border-color;
      border-right: none;
    }
    .dx-selectbox-container {
      .dx-dropdowneditor-field-template-wrapper {
        padding: 0px 4px;
        width: 90px;
        > div {
          @include flexboxv2();
          @include align-itemsv2(center);
          @include justify-contentv2(center);
          img {
            height: 18px !important;
          }
        }
        .dx-texteditor {
          width: 40px;
          .dx-texteditor-input-container {
            .dx-texteditor-input {
              width: 40px;
              padding: 0;
              font-size: 12px;
            }
          }
        }
      }
      .dx-texteditor-buttons-container {
        width: 16px;
        min-width: 16px;
        .dx-dropdowneditor-button {
          // width: 12px;
          min-width: 12px;
          .dx-button-content {
            .dx-dropdowneditor-icon {
              width: 14px;
              min-width: 14px;
              background: none !important;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
  .m-l-input-control-with-dropdown {
    &.dx-editor-outlined {
      @include border-radius(0px, 4px, 4px, 0px !important);
    }
  }
  .dx-selectbox-container {
    .dx-texteditor-container {
      .dx-texteditor-input-container {
        .dx-texteditor-input {
          font-size: 14px;
        }
      }
    }
    .dx-dropdowneditor-icon {
      &:before {
        margin-top: -8px;
        margin-left: -14px;
      }
    }
  }
}
.dx-selectbox-popup-wrapper {
  z-index: 9999 !important;
  .dx-popup-normal {
    padding: 0px;
    @include border-radius-all(4px !important);
    .dx-popup-content {
      .dx-list {
        .dx-scrollable-wrapper {
          .dx-scrollable-container {
            .dx-scrollable-content {
              .dx-scrollview-content {
                .dx-list-item {
                  padding: 10px 16px;
                  .dx-list-item-content {
                    padding: 0px;
                    font-size: $text-lg;
                    font-weight: $font-weight-normal;
                    color: $app-brand-secondary;
                    font-family: $font-primary;
                    @include flexboxv2();
                    @include align-itemsv2(center);
                    @include justify-contentv2(flex-start);
                    div {
                      > div {
                        font-size: 11px;
                        color: $app-brand-secondary;
                        padding-left: 2px;
                      }
                    }
                  }
                  &.dx-state-hover {
                    background-color: $grid-row-selection;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.dx-autocomplete-popup-wrapper {
  z-index: 9999 !important;
  .dx-popup-normal {
    padding: 0px;
    @include border-radius-all(4px !important);
    .dx-popup-content {
      .dx-list {
        .dx-scrollable-wrapper {
          .dx-scrollable-container {
            .dx-scrollable-content {
              .dx-scrollview-content {
                .dx-list-item {
                  padding: 10px 16px;
                  .dx-list-item-content {
                    padding: 0px;
                    font-size: $text-lg;
                    font-weight: $font-weight-normal;
                    color: $app-brand-secondary;
                    font-family: $font-primary;
                    @include flexboxv2();
                    @include align-itemsv2(center);
                    @include justify-contentv2(flex-start);
                    div {
                      > div {
                        font-size: 11px;
                        color: $app-brand-secondary;
                        padding-left: 2px;
                      }
                    }
                  }
                  &.dx-state-hover {
                    background-color: $grid-row-selection;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.m-l-button-vertical-center-align {
  height: 100%;
  width: 35px;
  min-height: 60px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-start);

  .app-c-btn {
    margin-top: 2px;
    min-width: 35px;
    @media screen and (max-width: 768px) {
      margin-top: 0px;
    }
  }
}
.m-c-date-picker {
  .dx-dropdowneditor-input-wrapper {
    .dx-texteditor-container {
      .dx-texteditor-buttons-container {
        .dx-button-content {
          .dx-dropdowneditor-icon {
            &:before {
              content: url('../../../assets/images/icons/calendar.svg');
            }
          }
        }
      }
    }
  }
}
.m-c-input-control {
  .dx-placeholder::before {
    font-size: $text-lg;
    line-height: 1.7;
    color: $placeholder-color;
    padding: 6px 10px 5px;
  }
  &.m-c-select-box {
    .dx-dropdowneditor-icon {
      &:before {
        content: '\f016';
        font-size: $text-md;
        color: $dark-blue;
        font-weight: $font-weight-bold;
        margin-top: -8px;
      }
    }
    &.m-c-st-box {
      max-width: 143px;
    }
  }
  &.dx-dropdowneditor {
    &.dx-state-hover,
    &.dx-state-active {
      .dx-dropdowneditor-icon {
        background-color: $grid-row-selection;
      }
    }
  }
  &.dx-dropdowneditor-active {
    .dx-dropdowneditor-icon {
      background-color: $grid-row-selection;
    }
  }
  &.m-c-multiselect-dropdown {
    position: relative;

    .dx-dropdowneditor-input-wrapper {
      .dx-texteditor-container {
        .dx-texteditor-input-container {
          padding-bottom: 0px;
          .dx-texteditor-input {
            height: 29px;
            min-height: 29px;
            margin: 2px;
            @include flex-growv2(1);
          }
        }
        .dx-tag {
          @include align-selfv2(center);
          .dx-tag-content {
            background: $grid-row-selection;
            @include border-radius-all(34px);
            @include flexboxv2();
            @include align-itemsv2(center);
            padding: 2px 10px;
            margin: 4px 0 4px 4px;
            span {
              padding-left: 16px;
              font-size: $text-sm-plus;
              line-height: 1.7;
              color: $app-brand-primary;
            }
            .dx-tag-remove-button {
              left: 0;
              &:before {
                width: 1px;
                height: 9px;
                background: $dark-blue;
              }
              &:after {
                width: 9px;
                height: 1px;
                background: $dark-blue;
              }
            }
          }
          & + .dx-texteditor-input {
            border: 1px solid $plc-color;
          }
        }
      }
    }
    &:after {
      display: block;
      content: url('../../../assets/images/icons/chevron-down.svg');
      position: absolute;
      top: 47%;
      transform: translateY(-50%);
      right: 14px;
      pointer-events: none;
    }
    .dx-texteditor-container {
      padding-right: 30px;
    }
  }
}
.dx-tagbox-popup-wrapper {
  .dx-popup-normal {
    padding-bottom: 18px;
  }
  .dx-checkbox {
    .dx-checkbox-icon {
      width: 18px;
      height: 18px;
      position: relative;
      // border: 1.5px solid $app-brand-secondary !important;
      border: 2px solid $dark-blue;
    }
    &.dx-checkbox-checked {
      .dx-checkbox-icon {
        &:before {
          top: 50%;
          margin-top: auto;
          left: 40%;
          transform: translate(-50%, -50%);
          margin-left: auto;
          font-size: 12px;
          font-weight: $font-weight-bold;
          // color: $app-brand-secondary;
          color: $dark-blue;
        }
      }
    }
  }

  .dx-list-item-content {
    font-size: $text-md;
    line-height: 1.71;
    padding-left: 0px !important;
    margin-left: 16px;
    color: $app-brand-secondary;
    font-weight: $font-weight-semibold;
  }
  .dx-scrollview-content {
    .dx-item {
      @include flexboxv2();
      @include align-itemsv2(center);
      &.dx-state-hover {
        background-color: $grid-row-selection;
      }
      &.dx-list-item-selected {
        .dx-checkbox-icon {
          border: 2px solid $dark-blue;
        }
      }
    }
  }
  .dx-list-select-checkbox {
    margin-bottom: 0px;
    margin-left: 16px;
  }
  .dx-list-select-all {
    @include flexboxv2();
    @include align-itemsv2(center);
    margin-bottom: 16px;
    .dx-list-select-all-label {
      padding: 0px 19px;
    }
  }
  .dx-checkbox-indeterminate {
    .dx-checkbox-icon::before {
      width: 5px;
      top: 48%;
      margin-top: auto;
      left: 50%;
      margin-left: auto;
      transform: translate(-50%, -50%);
      background-color: $app-brand-secondary;
      height: 5px;
      @include border-radius-all(0);
    }
  }
  .dx-list-select-all-checkbox {
    margin-left: 16px;
    margin-top: -4px;
  }
}
.m-l-category-dropdown-wrap {
  margin: 18px 0px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(center);
  .m-l-category__dropdown-block {
    width: 115px;
    .m-c-select-box {
      height: 28px;
      border: 1px solid $grid-row-selection;
      @include border-radius-all(4px);
      &.dx-state-hover,
      &.dx-state-focused {
        border-color: $dark-blue;
      }

      .dx-selectbox-container {
        .dx-texteditor-container {
          .dx-texteditor-input-container {
            .dx-texteditor-input {
              padding: 0px 0px 0px 4px;
              height: 20px;
              min-height: 28px;
              font-size: $text-lg;
              font-weight: $font-weight-semibold;
              line-height: normal;
              color: $secondary-brown;
              text-align: center;
            }
            .dx-placeholder {
              font-size: $text-lg;
              font-weight: $font-weight-normal;
              color: $placeholder-color;
              &::before {
                padding: 0 6px;
                color: $placeholder-color;
              }
            }
          }
          .dx-texteditor-buttons-container {
            .dx-dropdowneditor-button {
              width: 20px;
              min-width: 25px;
              .dx-button-content {
                .dx-dropdowneditor-icon {
                  width: 25px;
                  height: 26px;
                  position: relative;
                  top: -1px;
                  right: 1px;
                  // border-radius: 8px 8px 8px 8px;
                  &:before {
                    margin-top: -8px;
                    margin-left: -9px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.m-l-category-list-block {
  position: absolute;
  top: 36px;
  right: -22px;
  list-style: none;
  margin: 0;
  padding: 0px 0px;
  width: 89px;
  z-index: 3;
  background-color: $white;
  @include border-radius-all(4px);
  @include boxShadow(0 6px 12px rgba(0, 0, 0, 0.175));
  li {
    padding: 10px 16px;
    cursor: pointer;

    span {
      font-size: $text-lg;
      //font-weight: $font-weight-bold;
      color: $app-brand-secondary;
      line-height: normal;
    }
    // + li {
    //   margin-top: 6px;
    // }
    &:hover {
      background-color: $grid-row-selection;
    }
  }
}
.m-l-default-checkbox-unit {
  margin-bottom: 4px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(space-between);
  .m-l-input-label {
    margin-bottom: 0px;
  }
  .m-l-checkbox--small-label {
    font-size: $text-md;
    font-weight: $font-weight-normal;
    color: $app-brand-secondary;
    line-height: normal;
    margin-left: 6px;
  }
}
.m-l-input-group-with__btn-block {
  @include flexboxv2();
  @include align-itemsv2(flex-start);
  .m-l-user-role-category-select-box {
    flex-grow: 1;
    margin-right: 8px;
    min-width: 0;
  }
}
.m-l-user-role-category-select-box {
  .m-l-user-role-category__list {
    margin: 12px -6px 0px;
    @include flexboxv2();
    @include align-itemsv2(center);
    @include flex-wrapv2(wrap);
    .m-l-user-role-category-item {
      background-color: $grid-row-selection;
      padding: 1px 6px 2px;
      margin: 0px 4px 6px;
      @include border-radius-all(34px);
      svg {
        cursor: pointer;
      }
      .role-category {
        color: $app-brand-primary;
        font-size: $text-sm-plus;
        font-weight: $font-weight-normal;
        padding-left: 3px;
      }
    }
  }
}
.flag-name {
  margin-left: 16px;
}
.m-l-expand-btn-wrap {
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(flex-end);
  svg {
    width: 15px;
  }
  .app-c-icon-only-btn {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
}
.dx-editor-cell {
  .dx-editor-container {
    .dx-texteditor {
      .dx-dropdowneditor-input-wrapper {
        // border: 1px solid #90a1b5;
        @include border-radius-all(4px);
      }
    }
  }
}
.m-l-grid-top-header__section {
  padding: 13px 16px;
  border-bottom: 1px solid $semi-gray;
  @include flexboxv2();
  @include align-itemsv2(center);

  @media screen and (max-width: 480px) {
    display: block;
  }

  .left-section {
    width: 100%;
    h2 {
      color: $text-color;
      font-size: $text-md;
      font-weight: $font-weight-bold;
      white-space: nowrap;
      margin-bottom: 0px;

      @media screen and (max-width: 1200px) {
        white-space: normal;
      }
    }
    .m-l-toggle-switch {
      padding-left: 15px;
      padding-right: 15px;
    }
    .m-l-grid-top-header-list-row {
      // list-style: none;
      // margin: 0;
      // padding: 0;
      @include flexboxv2();
      @include align-itemsv2(center);
      max-width: 100%;
      .row {
        width: 100%;
      }
      .m-l-grid-top-header-list-first-column {
        width: 54px;
      }
      .m-l-grid-top-header-list-column {
        // width: 221px;
        // width: calc((100% / 6) - 16px);
        margin-top: 8px;
        margin-bottom: 8px;
        @include flexboxv2();
        @include align-itemsv2(center);
        @include justify-contentv2(space-between);
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
  .right-section {
    margin-left: auto;
    @include flexboxv2();
    @include align-itemsv2(center);

    @media screen and (max-width: 480px) {
      margin-left: 0px;
      margin-top: 12px;
    }
  }
}
.m-l-status-field-mobile-view-row {
  @include flexboxv2();
}
.m-l-status-field-mobile-view-column {
  @media screen and (max-width: 767px) {
    order: 2;
  }
}
.m-l-status-field-mobile-view-column-status {
  @media screen and (max-width: 767px) {
    order: 1;
  }
}
.m-c-selectbox-with-iconflag {
  @include flexboxv2();
  @include align-itemsv2(center);
  height: 34px;
  margin-left: 8px;
  .m-c-slbox-flag {
    width: 24px;
    height: 24px;
    @include border-radius-all(7px);
    flex-shrink: 0;
  }
}
.m-l-error-state-text {
  font-weight: $font-weight-normal;
  font-size: $text-md;
  line-height: 1.33;
  color: $red;
  margin-top: 4px;
}

//hide default devextreme error message
// .dx-invalid-message.dx-overlay {
//   display: none !important;
// }

.dx-invalid {
  &.dx-texteditor {
    &.dx-editor-filled {
      &.dx-show-invalid-badge {
        .dx-texteditor-input-container::after {
          display: none;
        }
      }
    }
    &.dx-editor-outlined {
      &.dx-show-invalid-badge {
        .dx-texteditor-input-container::after {
          display: none;
        }
      }
    }
    &.dx-editor-underlined {
      &.dx-show-invalid-badge {
        .dx-texteditor-input-container::after {
          display: none;
        }
      }
    }
  }
}

.dx-invalid-message > .dx-overlay-content {
  color: $red;
  background-color: transparent;
  border-radius: 0px;
  height: auto;
  padding: 0px;
  font-weight: $font-weight-normal !important;
  font-size: $text-md;
  line-height: 1.33;
  top: 100% !important;
  bottom: auto !important;
  z-index: 10 !important;
  margin-top: 2px;

  @include transform(translate(0px, 0px) !important);
}
.m-l-toggle-button-horizontal-list {
  width: 100%;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include justify-contentv2(space-between);
}
.m-l-accordion__main-section {
  padding: 8px;
  .dx-accordion-item-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
}
.m-l-reviewer-right-block {
  position: absolute;
  top: 8px;
  right: 8px;
  @include flexboxv2();
  @include align-itemsv2(center);
  .m-l-reviewer-block {
    margin-right: 16px;
    @include flexboxv2();
    @include align-itemsv2(center);
    h5 {
      color: $app-brand-secondary;
      font-size: $text-lg;
      font-weight: $font-weight-bold;
      margin-bottom: 0px;
      margin-right: 8px;
    }
  }
  .m-l-reviewer-nmae-block {
    padding: 2px 10px;
    background-color: $grid-row-selection;
    @include border-radius-all(20px);
    span {
      color: $app-brand-primary;
      font-size: $text-lg;
      font-weight: $font-weight-semibold;
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.app-c-icon-only-fl {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  padding: 0px !important;
  .dx-button-content {
    padding: 0px;
  }
  &:hover {
    background-color: $grid-row-selection;
    border-color: $grid-row-selection;
  }
}
.m-c-cutom-label-in-textbox {
  margin-right: 8px !important;
  background: $grid-row-selection;
  border-radius: 22px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 39px;
  height: 18px;
  .dx-button-content {
    padding: 0px !important;
    .dx-button-text {
      position: relative;
      top: 0px;
      font-weight: $font-weight-normal;
      font-size: $text-md;
      line-height: 1.3;
      color: $dark-blue;
    }
  }
  &:hover {
    background: $grid-row-selection;
  }
}
//Datebox

.dx-datebox-wrapper {
  .dx-popup-normal {
    padding: 10px 10px;
    max-height: 350px;
    .dx-popup-content {
      .dx-calendar-view-month {
        margin: 0px;
        height: 314px;
        .dx-calendar-navigator {
          .dx-button {
            border-color: transparent;
            .dx-button-content {
              .dx-button-text {
                font-size: $text-xl-sm;
                font-weight: $font-weight-bold;
                color: $app-brand-primary;
              }
              .dx-icon {
                color: $app-brand-primary;
              }
            }
            &.dx-state-hover {
              background-color: $grid-row-selection;
              box-shadow: none;
            }
          }
        }
        .dx-calendar-body {
          // bottom: -8px;
          .dx-calendar-views-wrapper {
            .dx-widget {
              table {
                thead {
                  tr {
                    th {
                      font-size: $text-md;
                      font-weight: $font-weight-bold;
                      color: $app-brand-secondary;
                      font-family: $font-primary;
                      padding: 1px 0 16px;
                      border: none;
                      box-shadow: none;
                    }
                  }
                }
                tbody {
                  tr {
                    td {
                      &.dx-calendar-cell {
                        font-size: $text-md;
                        font-weight: $font-weight-normal;
                        color: $app-brand-secondary;
                        font-family: $font-primary;
                        height: 34px;
                        width: 27px;
                      }
                      &.dx-calendar-contoured-date {
                        @include border-radius-all(50%);
                        box-shadow: none;
                      }
                      &.dx-calendar-today,
                      &.dx-calendar-selected-date {
                        color: $white;
                        background-color: $app-brand-primary;
                        @include border-radius-all(50%);
                        box-shadow: none;
                      }
                      &.dx-state-hover {
                        color: $app-brand-primary;
                        background-color: $grid-row-selection;
                        box-shadow: none;
                        @include border-radius-all(50%);
                      }
                      &.dx-calendar-other-view {
                        color: #b0b0b0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.m-c-mail-compose-to-select-box {
  position: relative;
  .m-c-input-control {
    .dx-texteditor-input-container {
      .dx-texteditor-input {
        color: $app-brand-secondary;
      }
      .dx-placeholder {
        font-size: $text-lg;
        font-weight: $font-weight-normal;
        color: $placeholder-color;
        &::before {
          color: $placeholder-color;
        }
      }
    }
    &.m-c-select-for-to {
      .dx-texteditor-input {
        padding: 6px 8px 6px 31px !important;
      }
      .dx-placeholder {
        &::before {
          padding: 4px 10px 6px 31px;
        }
      }
    }
    &.m-c-select-for-cc {
      .dx-texteditor-input {
        padding: 7px 8px 6px 34px !important;
      }
      .dx-placeholder {
        &::before {
          padding: 5px 10px 6px 33px;
        }
      }
    }
  }
  span {
    z-index: 2;
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    left: 10px;
    color: $app-brand-secondary;
    font-size: $text-lg;
    font-weight: $font-weight-normal;
  }
}
.m-c-mail-compose-subject-input-box {
  position: relative;
  .m-c-input-control {
    .dx-texteditor-input-container {
      .dx-texteditor-input {
        color: $app-brand-secondary;
      }
      .dx-placeholder {
        font-size: $text-lg;
        font-weight: $font-weight-normal;
        color: $placeholder-color;
        &::before {
          color: $placeholder-color;
        }
      }
    }
    &.m-c-input-for-subject {
      .dx-texteditor-input-container {
        .dx-texteditor-input {
          padding: 6px 8px 6px 62px !important;
        }
      }
      .dx-placeholder {
        &::before {
          padding: 6px 10px 5px 62px;
        }
      }
    }
  }
  span {
    z-index: 2;
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    left: 10px;
    color: $app-brand-secondary;
    font-size: $text-lg;
    font-weight: $font-weight-normal;
  }
}
.m-c-mail-compose-area-control-h-396 {
  height: 342px;
  @include border-radius-all(8px);
  border: 1px solid $app-secondary-border-color;
  margin-bottom: 18px;
}
.m-l-ads-tbl-label {
  color: $app-brand-secondary;
  font-size: $text-md;
  font-weight: $font-weight-normal;
}
.m-l-ads-tbl-data {
  color: $app-brand-primary;
  font-size: $text-lg;
  font-weight: $font-weight-bold;
}
.m-l-ads--age-table-block {
  @include flexboxv2();
  @include align-itemsv2(center);
  .m-l-ads-table-right-part {
    width: 100%;
  }
  .m-l-ads-table-left-part {
    margin-left: auto;
    @include flexboxv2();
    @include align-itemsv2(center);
    .m-l-ads-tbl-data {
      margin-right: 16px;
    }
  }
}
//for fix the error message alignment issue
// .m-c-select-box,
// .m-c-multiselect-dropdown,
// .m-c-grid {
//   .dx-invalid-message > .dx-overlay-content {
//     transform: translate(0px, 36px) !important;
//   }
// }
.m-c-text-area {
  &.m-c-text-area-in-form {
    min-height: 100px;
  }
  &.m-c-text-area-custom {
    .dx-texteditor-container {
      min-height: 84px;
      .dx-texteditor-input-container {
        .dx-texteditor-input {
          color: $app-brand-secondary;
          padding: 6px 8px 6px 8px;
          min-height: 33px;
          cursor: auto;
          @include border-radius-all(4px);
        }
        .dx-placeholder {
          font-size: $text-lg;
          font-weight: $font-weight-normal;
          color: $placeholder-color;
        }
      }
    }
    &.dx-texteditor {
      &.dx-editor-outlined {
        border: 1px solid $app-secondary-border-color;
        @include border-radius-all(8px);
      }
    }
  }
  &.m-c-textrea-comments {
    .dx-texteditor-container {
      min-height: 94px;
    }
    &.dx-texteditor {
      &.dx-editor-outlined {
        border: 1px solid $app-secondary-border-color;
        @include border-radius-all(8px);
      }
    }
  }
  &.m-c-psc-text-area {
    .dx-texteditor-container {
      min-height: 60px;
      .dx-texteditor-input-container {
        .dx-texteditor-input {
          color: $app-brand-secondary;
          padding: 6px 8px 6px 8px;
          min-height: 33px;
          cursor: auto;
          @include border-radius-all(4px);
        }
        .dx-placeholder {
          font-size: $text-lg;
          font-weight: $font-weight-normal;
          color: $placeholder-color;
        }
      }
    }
    &.dx-texteditor {
      &.dx-editor-outlined {
        border: 1px solid $app-secondary-border-color;
        @include border-radius-all(8px);
      }
    }
  }
  &.m-c-textrea-sire-comments {
    .dx-texteditor-container {
      min-height: 60px;
    }
    &.dx-texteditor {
      &.dx-editor-outlined {
        border: 1px solid $app-secondary-border-color;
        @include border-radius-all(8px);
      }
    }
  }
  &.m-c-textrea-incindent-comments {
    .dx-texteditor-container {
      min-height: 108px;
    }
    &.dx-texteditor {
      &.dx-editor-outlined {
        border: 1px solid $app-secondary-border-color;
        @include border-radius-all(8px);
      }
    }
  }
  &.dx-texteditor {
    &.dx-editor-outlined {
      border: 1px solid $app-secondary-border-color;
      @include border-radius-all(4px);
    }
  }
  .dx-texteditor-input {
    font-weight: $font-weight-normal;
    font-size: $text-md;
    line-height: 1.33;
  }
  .dx-placeholder {
    color: $placeholder-color;
    font-weight: $font-weight-normal;
    font-size: $text-md;
    line-height: 1.33;
  }

  &.dx-invalid {
    border-color: $red !important;
  }
}
.m-l-reviewer-footer-block {
  display: none;
  @media screen and (max-width: 767px) {
    @include flexboxv2();
    margin-left: auto;
    position: static;
    @include justify-contentv2(flex-end);
    margin-bottom: 8px;
  }
  @media screen and (max-width: 575px) {
    @include flex-directionv2(column);
  }
  .m-l-apl-btn-wrap {
    @media screen and (max-width: 575px) {
      width: 100%;
      margin-top: 8px;
    }
  }
  .app-c-btn {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
}
.m-l-label-data-wrapper {
  min-height: 60px;
  @include flexboxv2();
  @include align-itemsv2(center);
  padding-top: 28px;
  @media (max-width: 768px) {
    min-height: auto;
    padding-top: 0px;
  }
}
.m-l-data-label {
  font-weight: $font-weight-normal;
  font-size: $text-lg;
  line-height: 1.71;
  color: $app-brand-secondary;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.m-c-text-area-control-h-fix {
  height: 200px;
  width: 100%;
}
.m-c-recmnd-btn-wrap {
  margin-top: 12px;
  @include flexboxv2();
  @include justify-contentv2(flex-end);
  @media (max-width: 480px) {
    @include flex-directionv2(column);
    width: 100%;
  }
  .app-c-btn {
    @media (max-width: 480px) {
      width: 100%;
    }
    + .app-c-btn {
      @media (max-width: 480px) {
        margin-left: 0px;
        margin-top: 8px;
      }
    }
  }
}
.m-l-files-attached-list {
  margin-left: 8px;
  @include flexboxv2();
  @include align-itemsv2(center);
  @include flex-wrapv2(wrap);
  .m-l-files-attached-block {
    padding: 2px 6px;
    margin: 0 3px 3px 0px;
    @include flexboxv2();
    @include align-itemsv2(center);
    background-color: $grid-row-selection;
    @include border-radius-all(34px);
    span {
      padding-right: 16px;
      font-size: $text-md;
      line-height: 1.7;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 92%;
    }
    .m-l-files-close-icon {
      margin-left: auto;
      @include flexboxv2();
      @include align-itemsv2(center);
      cursor: pointer;
    }
    .m-l-files-download {
      cursor: pointer;
    }
  }
}
.m-c-radio-button-column__block {
  @include flexboxv2();
  @include flex-directionv2(column);
  @include align-itemsv2(flex-start);
  .m-c-radio-button {
    margin-top: 6px;
  }
}

.m-c-form__inline {
  @include flexboxv2();
  @include align-itemsv2(center);

  .m-c-form__icon {
    @include flex-shrinkv2(0);
    ~ .m-c-form__input {
      margin-left: 8px;
    }
  }
  .m-c-form__input {
    min-width: 0;
    width: 100%;
    ~ .m-c-form__icon {
      margin-left: 8px;
    }
  }
}
